import { compoundSidePanelFields } from 'components/SidePanel/CompoundSidePanelFields';

import {
  AISEngineVessel,
  AISVesselFieldId,
  CompoundPopupFieldMetadata,
  LabelFieldMetadata,
  PrintableVesselField,
  Vessel,
  VesselFieldHighlightConfig,
  VesselFieldId,
  VesselFieldsMetadata,
  VesselFieldValue,
} from './types';
import { VesselFieldHighlight } from './highlight-vessels';
import { VesselFieldsConfig } from './flotilla-config';
import moment from 'moment';
// import { locationTime } from './timezone';

const FAST_UPDATE_FREQUENCY_MS = 1000 * 300;
const SLOW_UPDATE_FREQUENCY_MS = 1000 * 60 * 60;
const GLACIAL_UPDATE_FREQUENCY_MS = 1000 * 60 * 60 * 4;

export function stringifyFieldValue<T extends VesselFieldValue>(
  input: T,
  field: PrintableVesselField<T>
) {
  if (field.emptyValues && field.emptyValues.includes(input))
    return field.emptyValueReplacementStr;
  try {
    return field.getDisplayString(input);
  } catch (err) {
    console.error('Error coersing ', input, ' to string');
    // TODO: Log to Sentry?
    return field.emptyValueReplacementStr;
  }
}

export function getFieldHighlight<T extends VesselFieldValue>(
  fieldId: VesselFieldId,
  vessels: AISEngineVessel[]
): VesselFieldHighlight<T> | null {
  if (vesselFields[fieldId].highlightConfig)
    return new VesselFieldHighlight<T>(
      fieldId,
      vessels.map((v) => v[fieldId]) as T[],
      vesselFields[fieldId].emptyValues,
      vesselFields[fieldId].highlightConfig as VesselFieldHighlightConfig
    );
  else return null;
}

export function getAISFieldHighlight<T extends VesselFieldValue>(
  fieldId: AISVesselFieldId,
  vessels: AISEngineVessel[]
): VesselFieldHighlight<T> | null {
  if (vesselFields[fieldId].highlightConfig)
    return new VesselFieldHighlight<T>(
      fieldId,
      vessels.map((v) => v[fieldId]) as T[],
      vesselFields[fieldId].emptyValues,
      vesselFields[fieldId].highlightConfig as VesselFieldHighlightConfig
    );
  else return null;
}

export function generateCountDescFunc(prescript: string) {
  return (count: number, term: string, representative?: boolean) => {
    const isETASearchBefore = prescript
      .toLowerCase()
      .includes('with eta before');
    const isETASearchAfter = prescript.toLowerCase().includes('with eta');

    if (isETASearchBefore) {
      const date = moment().add(term, 'days');
      return `${count} vessel${
        count > 1 ? 's' : ''
      } with ETA before ${date.format('Do MMM YYYY')}`;
    }
    if (isETASearchAfter) {
      const date = moment().add(term, 'days');
      return `${count} vessel${
        count > 1 ? 's' : ''
      } with ETA after ${date.format('Do MMM YYYY')}`;
    }
    let value = term;
    return representative
      ? `Vessels ${prescript}`
      : `${count} vessel${count > 1 ? 's' : ''} ${prescript} ${value}`;
  };
}

// TODO: Move the helper functions elsewhere
const toTitleCase = (phrase: string): string => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export function secondsToDhms(seconds: number): string {
  seconds = Number(seconds);
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);

  if (seconds < 3600) {
    return 'Less than an hour';
  }

  const dDisplay = d > 0 ? d + (d === 1 ? ' day, ' : ' days, ') : '';
  const hDisplay = h > 0 ? h + (h === 1 ? ' hr' : ' hrs') : '';
  return dDisplay + hDisplay;
}

export function getSidePanelDisplayableFields(): VesselFieldId[] {
  const defaultFields = Object.keys(vesselFields).map((fieldId) => ({
    [fieldId]: vesselFields[fieldId],
  }));
  const sidePanelFields = Object.keys(compoundSidePanelFields).map(
    (fieldId) => ({ [fieldId]: compoundSidePanelFields[fieldId] })
  );
  const displayFields = [...sidePanelFields, ...defaultFields].sort((a, b) => {
    const aKey = Object.keys(a)[0];
    const bKey = Object.keys(b)[0];

    if (a[aKey].displayPriority > b[bKey].displayPriority) {
      return 1;
    } else if (a[aKey].displayPriority < b[bKey].displayPriority) {
      return -1;
    } else {
      return 0;
    }
  });

  const displayFieldIds = displayFields.map((field) => Object.keys(field)[0]);

  return displayFieldIds;
}

export function getHighlightableFields(): VesselFieldId[] {
  return (Object.keys(vesselFields) as VesselFieldId[]).filter(
    (fieldId) => !!vesselFields[fieldId].highlightConfig
  );
}

export function getLabellableFields(): VesselFieldId[] {
  return Object.keys(labelFields) as VesselFieldId[];
}

export function getPopuppableFields(): VesselFieldId[] {
  return (Object.keys(vesselFields) as VesselFieldId[])
    .filter((fieldId) => vesselFields[fieldId].popupEnabled)
    .concat(Object.keys(compoundPopupFields));
}

export const labelFields: { [key: string]: LabelFieldMetadata } = {
  label_name: {
    displayPriority: 0,
    getDisplayString: (vessel: Vessel) => {
      return vessel.name ? vessel.name : '';
    },
    shortDesc: 'Name',
    longDesc: 'Vessel Name',
    emptyValues: [],
    emptyValueReplacementStr: 'Unknown',
  },
  label_name_and_speed: {
    displayPriority: 0,
    getDisplayString: (vessel: Vessel) => {
      return `${vessel.name ? vessel.name : ''}${
        vessel.speed ? `: ${vessel.speed} kts` : ''
      }`;
    },
    shortDesc: 'Name and Speed',
    longDesc: 'Vessel Name and Speed',
    emptyValues: [],
    emptyValueReplacementStr: 'Unknown',
  },
};

export const compoundPopupFields: {
  [key: string]: CompoundPopupFieldMetadata;
} = {
  // compound_crewSigners: {
  //   compoundField: true,
  //   displayPriority: 21,
  //   shortDesc: 'Crew',
  //   longDesc: 'Crew to Change',
  //   fieldInputs: ['crewHomeAirports'],
  //   emptyValueReplacementStr: 'None',
  //   emptyValues: [],
  //   getDisplayString: function (vessel: Vessel) {
  //     function offsignerCount(vessel: Vessel) {
  //       let res = 0;
  //       for (const k in vessel.crewHomeAirports) {
  //         res += vessel.crewHomeAirports[k].offsigners;
  //       }
  //       return res;
  //     }
  //     function onsignerCount(vessel: Vessel) {
  //       let res = 0;
  //       for (const k in vessel.crewHomeAirports) {
  //         res += vessel.crewHomeAirports[k].onsigners;
  //       }
  //       return res;
  //     }
  //     if (!vessel.crewHomeAirports) return this.emptyValueReplacementStr;
  //     return `${onsignerCount(vessel)} on, ${offsignerCount(vessel)} off`;
  //   },
  // },
  // compound_localTime: {
  //   compoundField: true,
  //   displayPriority: 31,
  //   shortDesc: 'Local Time',
  //   longDesc: 'Local Time Onboard Vessel',
  //   fieldInputs: ['lat', 'lng'],
  //   emptyValueReplacementStr: '',
  //   emptyValues: [],
  //   getDisplayString: function (vessel: Vessel) {
  //     if (!vessel.lat || !vessel.lng) return '';
  //     return locationTime({ lat: vessel.lat, lon: vessel.lng });
  //   },
  // },
};

export const vesselFields: VesselFieldsMetadata = {
  // Sidepanel display fields
  imo: {
    displayPriority: 10, // Using tens so it's easier to insert compound fields
    display: true,
    popupEnabled: true,
    shortDesc: 'IMO',
    longDesc: 'IMO',
    command: 'imo',
    commandDescription: 'Enter a vessel IMO number',
    fieldType: 'string',
    includedInCSV: true,
    updateFrequencyMs: GLACIAL_UPDATE_FREQUENCY_MS,
    searchConfig: {
      searchPriority: 1,
      searchFieldDescription: 'IMO',
      indexTransformer: undefined,
    },
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
    emptyValueReplacementStr: '(Unknown IMO)',
    emptyValues: [null, ''],
    countDescFunc: generateCountDescFunc('with IMO'),
  },
  // secondsToNextPort: {
  //   displayPriority: 20,
  //   display: true,
  //   popupEnabled: true,
  //   shortDesc: 'Time to Next Port',
  //   longDesc: 'Time to Next Port',
  //   command: 'eta',
  //   commandDescription: 'Use the slider for estimated time to next port',
  //   fieldType: 'number',
  //   highlightConfig: {
  //     highlightType: 'number',
  //     colorPalette: 'sequential',
  //   },
  //   includedInCSV: true,
  //   updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
  //   searchConfig: {
  //     searchPriority: 1,
  //     searchFieldDescription: 'ETA',
  //     indexTransformer: undefined,
  //   },
  //   emptyValues: ['', null, undefined],
  //   emptyValueReplacementStr: 'Unknown',
  //   getDisplayString: function (value) {
  //     return this.emptyValues.includes(value)
  //       ? this.emptyValueReplacementStr
  //       : secondsToDhms(value);
  //   },
  //   countDescFunc: generateCountDescFunc('with ETA before'),
  // },
  speed: {
    displayPriority: 30,
    display: true,
    popupEnabled: true,
    shortDesc: 'Speed',
    longDesc: 'Vessel Speed',
    command: null,
    commandDescription: null,
    fieldType: 'number',
    highlightConfig: {
      highlightType: 'number',
      colorPalette: 'sequential',
    },
    includedInCSV: true,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    emptyValues: [null, undefined, NaN],
    emptyValueReplacementStr: 'Unknown',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value) + ' knots';
    },
    countDescFunc: generateCountDescFunc('with speed'),
  },
  nextPortName: {
    displayPriority: 40,
    popupEnabled: true,
    display: false,
    shortDesc: 'Next Port',
    longDesc: 'Next Port Name',
    command: 'nextport',
    commandDescription:
      'Enter a port name to select vessels by their next port',
    fieldType: 'string',
    includedInCSV: true,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    highlightConfig: {
      highlightType: 'enum',
      colorPalette: 'qualitative',
    },
    searchConfig: {
      searchPriority: 2,
      searchFieldDescription: 'Next Port Name',
      indexTransformer: undefined,
    },
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : toTitleCase(String(value));
    },
    emptyValues: [null, ''],
    emptyValueReplacementStr: '(Unknown)',
    countDescFunc: generateCountDescFunc('going to port'),
  },
  arrival: {
    displayPriority: 50,
    popupEnabled: true,
    display: false,
    shortDesc: 'Arrival',
    longDesc: 'Arrival at Next Port',
    command: null,
    commandDescription: null,
    fieldType: 'date',
    includedInCSV: true,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    emptyValues: [null, new Date(0)],
    emptyValueReplacementStr: '-',
    highlightConfig: {
      highlightType: 'date',
      colorPalette: 'sequential',
    },
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : new Date(value).toLocaleDateString(
            'en-US',
            VesselFieldsConfig.dateDisplayOptions
          );
    },
    countDescFunc: generateCountDescFunc('arriving at'),
  },
  source: {
    displayPriority: 60,
    display: true,
    popupEnabled: true,
    shortDesc: 'Source',
    longDesc: 'Data Retrieved from',
    command: null,
    commandDescription: 'Enter a data source',
    fieldType: 'enum',
    includedInCSV: true,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    emptyValues: ['', null, undefined],
    emptyValueReplacementStr: '-',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
    countDescFunc: generateCountDescFunc('with data retrieved from'),
  },
  // lastPortName: {
  //   displayPriority: 70,
  //   popupEnabled: true,
  //   display: false,
  //   shortDesc: 'Last Port',
  //   longDesc: 'Last Port Name',
  //   fieldType: 'string',
  //   command: 'lastport',
  //   commandDescription: '',
  //   includedInCSV: true,
  //   updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
  //   highlightConfig: {
  //     highlightType: 'enum',
  //     colorPalette: 'qualitative',
  //   },
  //   searchConfig: {
  //     searchPriority: 3,
  //     searchFieldDescription: 'Last Port Name',
  //     indexTransformer: (value: string) => value,
  //   },
  //   getDisplayString: function (value) {
  //     return this.emptyValues.includes(value)
  //       ? this.emptyValueReplacementStr
  //       : toTitleCase(String(value));
  //   },
  //   emptyValues: [null, ''],
  //   emptyValueReplacementStr: '(Unknown)',
  //   countDescFunc: generateCountDescFunc('leaving from LOCODE'),
  // },
  // departure: {
  //   displayPriority: 80,
  //   popupEnabled: true,
  //   display: false,
  //   shortDesc: 'Departed',
  //   longDesc: 'Departure from Last Port',
  //   command: null,
  //   commandDescription: null,
  //   fieldType: 'date',
  //   includedInCSV: true,
  //   updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
  //   emptyValues: [null, new Date(0)],
  //   emptyValueReplacementStr: '-',
  //   highlightConfig: {
  //     highlightType: 'date',
  //     colorPalette: 'sequential',
  //   },
  //   getDisplayString: function (value) {
  //     return this.emptyValues.includes(value)
  //       ? this.emptyValueReplacementStr
  //       : new Date(value).toLocaleDateString(
  //           'en-US',
  //           VesselFieldsConfig.dateDisplayOptions
  //         );
  //   },
  //   countDescFunc: generateCountDescFunc('departed at'),
  // },
  flag: {
    displayPriority: 90,
    display: true,
    popupEnabled: true,
    shortDesc: 'Flag',
    longDesc: 'Flag State',
    command: 'flag',
    commandDescription: 'Enter the name of a flag state',
    fieldType: 'string',
    highlightConfig: {
      highlightType: 'enum',
      colorPalette: 'qualitative',
    },
    searchConfig: {
      searchPriority: 6,
      searchFieldDescription: 'Flag',
      indexTransformer: (value: string) => value,
    },
    includedInCSV: true,
    updateFrequencyMs: SLOW_UPDATE_FREQUENCY_MS,
    emptyValues: ['', null, undefined],
    emptyValueReplacementStr: 'Unknown',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value).toUpperCase();
    },
    countDescFunc: generateCountDescFunc('with flag state'),
  },
  // picLabel: {
  //   displayPriority: 100,
  //   display: true,
  //   popupEnabled: true,
  //   shortDesc: 'In-Charge',
  //   longDesc: 'Person In Charge',
  //   command: 'pic',
  //   commandDescription: 'Enter the email address of a person-in-charge',
  //   fieldType: 'string',
  //   highlightConfig: {
  //     highlightType: 'enum',
  //     colorPalette: 'qualitative',
  //   },
  //   searchConfig: {
  //     searchPriority: 7,
  //     searchFieldDescription: 'In-Charge',
  //     indexTransformer: (value: string) => value,
  //   },
  //   includedInCSV: true,
  //   updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
  //   emptyValues: ['', null, undefined],
  //   emptyValueReplacementStr: 'Not Assigned Yet',
  //   getDisplayString: function (value) {
  //     return this.emptyValues.includes(value)
  //       ? this.emptyValueReplacementStr
  //       : String(value);
  //   },
  //   countDescFunc: generateCountDescFunc('with in-charge'),
  // },
  course: {
    displayPriority: 110,
    popupEnabled: true,
    display: false,
    shortDesc: 'Course',
    longDesc: 'Vessel Course',
    command: null,
    commandDescription: null,
    fieldType: 'number',
    includedInCSV: true,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    emptyValues: [null, NaN],
    emptyValueReplacementStr: '-',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value) + '°';
    },
    countDescFunc: generateCountDescFunc('with Course'),
  },
  // owner: {
  //   displayPriority: 120,
  //   popupEnabled: true,
  //   display: true,
  //   shortDesc: 'Owner',
  //   longDesc: 'Vessel Owner',
  //   fieldType: 'string',
  //   command: 'owner',
  //   commandDescription: 'Enter the name of a vessel owner',
  //   includedInCSV: true,
  //   updateFrequencyMs: GLACIAL_UPDATE_FREQUENCY_MS,
  //   highlightConfig: {
  //     highlightType: 'enum',
  //     colorPalette: 'qualitative',
  //   },
  //   searchConfig: {
  //     searchPriority: 3,
  //     searchFieldDescription: 'Vessel Owner',
  //     indexTransformer: undefined,
  //   },
  //   getDisplayString: function (value) {
  //     return this.emptyValues.includes(value)
  //       ? this.emptyValueReplacementStr
  //       : toTitleCase(value);
  //   },
  //   emptyValues: [null, ''],
  //   emptyValueReplacementStr: '(Unknown)',
  //   countDescFunc: generateCountDescFunc('owned by'),
  // },
  // manager: {
  //   displayPriority: 130,
  //   popupEnabled: true,
  //   display: true,
  //   shortDesc: 'Manager',
  //   longDesc: 'Vessel Manager',
  //   fieldType: 'string',
  //   command: 'manager',
  //   commandDescription: 'Enter the name of a vessel manager',
  //   includedInCSV: true,
  //   updateFrequencyMs: GLACIAL_UPDATE_FREQUENCY_MS,
  //   highlightConfig: {
  //     highlightType: 'enum',
  //     colorPalette: 'qualitative',
  //   },
  //   searchConfig: {
  //     searchPriority: 3,
  //     searchFieldDescription: 'Vessel Manager',
  //     indexTransformer: undefined,
  //   },
  //   getDisplayString: function (value) {
  //     return this.emptyValues.includes(value)
  //       ? this.emptyValueReplacementStr
  //       : toTitleCase(value);
  //   },
  //   emptyValues: [null, ''],
  //   emptyValueReplacementStr: '(Unknown)',
  //   countDescFunc: generateCountDescFunc('managed by'),
  // },
  updatedAt: {
    displayPriority: 140,
    display: true,
    popupEnabled: true,
    shortDesc: 'Updated',
    longDesc: 'Vessel Updated At',
    command: null,
    commandDescription: null,
    fieldType: 'date',
    includedInCSV: true,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    emptyValues: [null, new Date(0)],
    emptyValueReplacementStr: '-',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : new Date(value).toLocaleDateString(
            'en-US',
            VesselFieldsConfig.dateDisplayOptions
          );
    },
    countDescFunc: generateCountDescFunc('updated at'),
  },
  // portCallsLastChanged: {
  //   displayPriority: 150,
  //   display: true,
  //   popupEnabled: false,
  //   shortDesc: 'Portcalls Changed',
  //   longDesc: 'Portcalls Last Changed AT',
  //   command: null,
  //   commandDescription: null,
  //   fieldType: 'date',
  //   includedInCSV: false,
  //   updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
  //   emptyValues: [null, new Date(0)],
  //   emptyValueReplacementStr: '-',
  //   getDisplayString: function (value) {
  //     return this.emptyValues.includes(value)
  //       ? this.emptyValueReplacementStr
  //       : new Date(value).toLocaleDateString(
  //           'en-US',
  //           VesselFieldsConfig.dateDisplayOptions
  //         );
  //   },
  //   countDescFunc: generateCountDescFunc('had portcalls last changed at'),
  // },
  status: {
    displayPriority: 160,
    display: true,
    popupEnabled: true,
    shortDesc: 'Status',
    longDesc: 'Current AIS Status',
    command: null,
    commandDescription: 'Enter an AIS status',
    fieldType: 'enum',
    highlightConfig: {
      highlightType: 'enum',
      colorPalette: 'qualitative',
    },
    searchConfig: {
      searchPriority: 6,
      searchFieldDescription: 'Status',
      indexTransformer: (value: string) => value,
    },
    includedInCSV: true,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    emptyValues: ['', null, undefined],
    emptyValueReplacementStr: 'Unknown',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
    countDescFunc: generateCountDescFunc('with AIS status'),
  },
  // operatingStatus: {
  //   displayPriority: 170,
  //   display: true,
  //   popupEnabled: false,
  //   shortDesc: 'Operating Status',
  //   longDesc: 'Operating Status',
  //   command: null,
  //   commandDescription: 'Enter an operating status',
  //   fieldType: 'string',
  //   highlightConfig: {
  //     highlightType: 'enum',
  //     colorPalette: 'qualitative',
  //   },
  //   searchConfig: {
  //     searchPriority: 6,
  //     searchFieldDescription: 'Operating Status',
  //     indexTransformer: (value: string) => value,
  //   },
  //   includedInCSV: true,
  //   updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
  //   emptyValues: ['', null, undefined],
  //   emptyValueReplacementStr: '-',
  //   getDisplayString: function (value) {
  //     return this.emptyValues.includes(value)
  //       ? this.emptyValueReplacementStr
  //       : String(value);
  //   },
  //   countDescFunc: generateCountDescFunc('with Operating status'),
  // },
  type: {
    displayPriority: 180,
    display: true,
    shortDesc: 'Type',
    popupEnabled: true,
    longDesc: 'Type of Vessel',
    command: 'type',
    commandDescription: 'Enter a vessel type',
    fieldType: 'string',
    highlightConfig: {
      highlightType: 'enum',
      colorPalette: 'qualitative',
    },
    searchConfig: {
      searchPriority: 7,
      searchFieldDescription: 'Type',
      indexTransformer: (value: string) => value,
    },
    includedInCSV: true,
    updateFrequencyMs: GLACIAL_UPDATE_FREQUENCY_MS,
    emptyValues: ['', null, undefined],
    emptyValueReplacementStr: 'Unknown',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
    countDescFunc: generateCountDescFunc('of type'),
  },
  nextPortCode: {
    displayPriority: 190,
    display: true,
    popupEnabled: true,
    shortDesc: 'Next Port',
    longDesc: 'Next Port LOCODE',
    command: 'nextportcode',
    commandDescription: 'Enter the LOCODE of the next port',
    fieldType: 'string',
    includedInCSV: true,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    highlightConfig: {
      highlightType: 'enum',
      colorPalette: 'qualitative',
    },
    searchConfig: {
      searchPriority: 2,
      searchFieldDescription: 'Next Port LOCODE',
      indexTransformer: undefined,
    },
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value).toUpperCase();
    },
    emptyValues: [null, ''],
    emptyValueReplacementStr: '(Unknown)',
    countDescFunc: generateCountDescFunc('going to LOCODE'),
  },
  // lastPortCode: {
  //   displayPriority: 200,
  //   display: true,
  //   popupEnabled: true,
  //   shortDesc: 'Last Port',
  //   longDesc: 'Last Port LOCODE',
  //   command: 'lastportcode',
  //   commandDescription: 'Enter the LOCODE of the most recent port',
  //   fieldType: 'string',
  //   includedInCSV: true,
  //   updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
  //   highlightConfig: {
  //     highlightType: 'enum',
  //     colorPalette: 'qualitative',
  //   },
  //   searchConfig: {
  //     searchPriority: 3,
  //     searchFieldDescription: 'Last Port LOCODE',
  //     indexTransformer: (value: string) => value,
  //   },
  //   getDisplayString: function (value) {
  //     return this.emptyValues.includes(value)
  //       ? this.emptyValueReplacementStr
  //       : String(value).toUpperCase();
  //   },
  //   emptyValues: [null, ''],
  //   emptyValueReplacementStr: '(Unknown)',
  //   countDescFunc: generateCountDescFunc('leaving from LOCODE'),
  // },
  dwt: {
    displayPriority: 210,
    display: true,
    popupEnabled: false,
    shortDesc: 'DWT',
    longDesc: 'Deadweight Tonnage',
    command: null,
    commandDescription: null,
    fieldType: 'number',
    includedInCSV: true,
    updateFrequencyMs: GLACIAL_UPDATE_FREQUENCY_MS,
    emptyValues: [null, undefined, NaN],
    emptyValueReplacementStr: 'Unknown',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
    countDescFunc: generateCountDescFunc('with tonnage'),
  },
  mmsi: {
    displayPriority: 220,
    display: true,
    popupEnabled: false,
    shortDesc: 'MMSI',
    longDesc: 'MMSI',
    fieldType: 'string',
    command: 'mmsi',
    commandDescription: 'Enter an MMSI',
    includedInCSV: true,
    updateFrequencyMs: GLACIAL_UPDATE_FREQUENCY_MS,
    searchConfig: {
      searchPriority: 10,
      searchFieldDescription: 'MMSI',
      indexTransformer: undefined,
    },
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
    emptyValueReplacementStr: '(Unknown MMSI)',
    emptyValues: [null, ''],
    countDescFunc: generateCountDescFunc('with MMSI'),
  },
  // addedBy: {
  //   displayPriority: 230,
  //   display: true,
  //   popupEnabled: false,
  //   shortDesc: 'Creator',
  //   longDesc: 'Added By',
  //   command: null,
  //   commandDescription:
  //     'Enter the email address of the person who entered the data',
  //   fieldType: 'string',
  //   highlightConfig: {
  //     highlightType: 'enum',
  //     colorPalette: 'qualitative',
  //   },
  //   includedInCSV: true,
  //   updateFrequencyMs: GLACIAL_UPDATE_FREQUENCY_MS,
  //   emptyValues: ['', null, undefined],
  //   emptyValueReplacementStr: 'Greywing',
  //   getDisplayString: function (value) {
  //     return this.emptyValues.includes(value)
  //       ? this.emptyValueReplacementStr
  //       : String(value);
  //   },
  //   countDescFunc: generateCountDescFunc('created by'),
  // },

  // Non-display fields
  id: {
    displayPriority: 1000,
    display: false,
    popupEnabled: false,
    shortDesc: 'ID',
    longDesc: 'Vessel ID',
    fieldType: 'number',
    command: null,
    commandDescription: null,
    includedInCSV: false,
    updateFrequencyMs: null,
    highlightConfig: undefined,
    searchConfig: undefined,
    emptyValues: [null],
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
    emptyValueReplacementStr: '(Unknown ID)',
    countDescFunc: generateCountDescFunc('with ID'),
  },
  name: {
    displayPriority: 1010,
    display: false,
    popupEnabled: false,
    shortDesc: 'Name',
    longDesc: 'Vessel Name',
    fieldType: 'string',
    command: 'name',
    commandDescription: 'Enter a vessel name',
    includedInCSV: true,
    updateFrequencyMs: SLOW_UPDATE_FREQUENCY_MS,
    highlightConfig: {
      highlightType: 'enum',
      colorPalette: 'qualitative',
    },
    searchConfig: {
      searchPriority: 0,
      searchFieldDescription: 'Name',
      indexTransformer: undefined,
    },
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
    emptyValueReplacementStr: '(Unknown Name)',
    emptyValues: [null, ''],
    countDescFunc: generateCountDescFunc('named'),
  },
  lat: {
    displayPriority: 1020,
    display: false,
    popupEnabled: false,
    shortDesc: 'Lat',
    longDesc: 'Latitude',
    fieldType: 'number',
    command: null,
    commandDescription: null,
    includedInCSV: true,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    emptyValues: [null, '', NaN],
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
    emptyValueReplacementStr: '-',
    countDescFunc: generateCountDescFunc('with Latitude'),
  },
  lng: {
    displayPriority: 1030,
    display: false,
    popupEnabled: false,
    shortDesc: 'Lon',
    longDesc: 'Longitude',
    fieldType: 'number',
    command: null,
    commandDescription: null,
    includedInCSV: true,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    emptyValues: [null, '', NaN],
    emptyValueReplacementStr: '-',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
    countDescFunc: generateCountDescFunc('with Longitude'),
  },
  riskStatus: {
    displayPriority: 1040,
    display: false,
    popupEnabled: false,
    shortDesc: 'Risk',
    longDesc: 'Risk Status',
    fieldType: 'enum',
    command: null,
    commandDescription: null,
    includedInCSV: false,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    emptyValues: ['', null],
    emptyValueReplacementStr: 'None',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
    countDescFunc: generateCountDescFunc('with Risk Status'),
  },
  image: {
    displayPriority: 1070,
    display: false,
    popupEnabled: false,
    shortDesc: 'Image',
    longDesc: 'Image URL',
    command: null,
    commandDescription: null,
    fieldType: 'string',
    includedInCSV: false,
    updateFrequencyMs: GLACIAL_UPDATE_FREQUENCY_MS,
    emptyValues: ['', null],
    emptyValueReplacementStr: '-',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
    countDescFunc: generateCountDescFunc('with Image URL'),
  },

  destinationLng: {
    displayPriority: 1080,
    display: false,
    popupEnabled: false,
    shortDesc: '',
    longDesc: '',
    command: null,
    commandDescription: null,
    fieldType: 'number',
    includedInCSV: false,
    updateFrequencyMs: GLACIAL_UPDATE_FREQUENCY_MS,
    emptyValues: [null, '', NaN],
    emptyValueReplacementStr: '-',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
    countDescFunc: generateCountDescFunc('with Longitude'),
  },
  destinationLat: {
    displayPriority: 1080,
    display: false,
    popupEnabled: false,
    shortDesc: '',
    longDesc: '',
    command: null,
    commandDescription: null,
    fieldType: 'number',
    includedInCSV: false,
    updateFrequencyMs: GLACIAL_UPDATE_FREQUENCY_MS,
    emptyValues: [null, '', NaN],
    emptyValueReplacementStr: '-',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
    countDescFunc: generateCountDescFunc('with Longitude'),
  },

  // d: {
  //   displayPriority:          ,
  //   display:                  ,
  //   shortDesc:                ,
  //   longDesc:                 ,
  //   fieldType:                ,
  //   highlightConfig:
  //   searchConfig:
  //   includedInCSV:            ,
  //   updateFrequencyMs:        ,
  //   emptyValues:              ,
  //   emptyValueReplacementStr: ,
  // getDisplayString: function(value) {
  //   return this.emptyValues.includes(value) ? this.emptyValueReplacementStr : String(value)
  // },
  //   countDescFunc:            generateCountDescFunc(),
  // },

  // ^^ Template for adding new fields
};

// Fields to be considered later
// 'picId' |
// 'tags';
