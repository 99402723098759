import Lottie from 'react-lottie';
import styled from 'styled-components/macro';

import { FlotillaMapConfig } from 'utils/flotilla-config';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  width: 100%;
  height: 100%;
`;

type Props = {
  size: number;
};

function Loader({ size }: Props) {
  return (
    <Wrapper>
      <Lottie options={FlotillaMapConfig.animationOptions} width={size} />
    </Wrapper>
  );
}

export default Loader;
