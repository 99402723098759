import { CSSProperties, ReactElement } from 'react';
import styles from '../../styles/components/shared/Typography.module.scss';

interface ITitleProps {
  children: string;
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  style?: CSSProperties;
}

interface ISubtitleProps {
  children: string;
}

interface ITextProps {
  align?: 'left' | 'center' | 'right';
  variant?: 'default' | 'muted' | 'error';
  size?: number;
  weight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
  children: (string | ReactElement)[] | string | ReactElement;
}

export const Title = ({ level = 1, children, style }: ITitleProps) => {
  const Component = `h${level}` as any;

  return (
    <Component className={`${styles.title} h${level}`} style={style}>
      {children}
    </Component>
  );
};

export const Subtitle = ({ children }: ISubtitleProps) => (
  <p className={styles.subtitle}>{children}</p>
);

export const Text = ({
  align = 'left',
  variant = 'default',
  size = 14,
  weight = 500,
  children,
}: ITextProps) => (
  <p
    className={`
      ${styles.text}
      variant-${variant}
      size-${size}px
      weight-${weight}
      align-${align}
    `}
  >
    {children}
  </p>
);

export const ErrorText = ({ children }: ITextProps) => (
  <p className={styles.errorText}>{children}</p>
);
