import { useContext, useMemo, useEffect } from 'react';
import styled from 'styled-components/macro';

// Components
import CardContainer from './CardContainer';
import CardHeader from './CardHeader';

// Context
import { StoreContext } from 'store/Store';
import { SidePanelFieldContext } from 'contexts/sidepanel-context';

// Types and Utilities
import { AISEngineVessel, VesselFieldId } from 'utils/types';
import {
  getSidePanelDisplayableFields,
  vesselFields,
  stringifyFieldValue,
} from 'utils/vesselFields';
import { compoundSidePanelFields } from './CompoundSidePanelFields';

// Styles
import { textGray } from 'lib/colors';
import { BREAK_POINT_XS } from 'lib/breakpoints';

interface MiscDataProps {
  vessel: AISEngineVessel;
}

const Subtitle = styled.span`
  display: inline-block;
  color: ${textGray};
  font-size: 0.7rem;
  text-transform: uppercase;
  line-height: 1;
`;

const AdditionalInfoWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0.5rem 0;
  padding: 0;
  row-gap: 1rem;
  li {
    margin: 0 !important;
    padding-right: 0.2rem;
    flex: 1 1 50%;
    list-style-type: none;
  }
  @media screen and (max-width: ${BREAK_POINT_XS}) {
    li {
      flex-basis: 100%;
    }
  }
`;

const FieldInfo = styled.li`
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: space-between;
`;

const FIELDS_IN_COURSE_HEADER: Array<VesselFieldId> = [
  'imo',
  'secondsToNextPort',
  'speed',
];

const MiscData = ({ vessel }: MiscDataProps) => {
  const { flotillaConfig } = useContext(StoreContext);
  const { activeRefs, highlightedField } = useContext(SidePanelFieldContext);

  useEffect(() => {
    if (
      highlightedField &&
      highlightedField in activeRefs.current &&
      activeRefs.current[highlightedField]
    ) {
      activeRefs.current[highlightedField].scrollIntoView(true, {
        behavior: 'smooth',
      });
    }
  }, [highlightedField, activeRefs]);

  const displayFields = useMemo(() => {
    return getSidePanelDisplayableFields()
      .reduce((acc: Array<any>, fieldId) => {
        if (
          !(
            flotillaConfig.sidePanelFields.includes(fieldId) &&
            fieldId in vesselFields &&
            vesselFields[fieldId].display &&
            !FIELDS_IN_COURSE_HEADER.includes(fieldId) &&
            !(fieldId in compoundSidePanelFields)
          )
        ) {
          return acc;
        }
        if (fieldId in vesselFields) {
          if (!vesselFields[fieldId].emptyValues.includes(vessel[fieldId])) {
            acc.push({ fieldId, data: vesselFields[fieldId] });
          }
        }
        return acc;
      }, [])
      .sort((a, b) => a.data.displayPriority - b.data.displayPriority);
  }, [flotillaConfig.sidePanelFields, vessel]);

  if (!displayFields.length) return null;
  return (
    <CardContainer>
      <CardHeader>Miscellaneous Data</CardHeader>
      <AdditionalInfoWrapper>
        {displayFields.map(({ fieldId, data }) => (
          <FieldInfo
            key={fieldId}
            ref={(element) => ((activeRefs.current as any)[fieldId] = element)}
          >
            <Subtitle>{data.longDesc}</Subtitle>
            <p style={{ margin: 0 }}>
              {stringifyFieldValue(vessel[fieldId], data)}
            </p>
          </FieldInfo>
        ))}
      </AdditionalInfoWrapper>
    </CardContainer>
  );
};

export default MiscData;
