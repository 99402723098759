import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import styled from 'styled-components/macro';

type Style = { [key: string]: string | number };

const IconWrapper = styled.div<{ $size: number }>`
  height: ${({ $size }) => `${$size + $size * 0.25}px`};
  width: ${({ $size }) => `${$size + $size * 0.25}px`};
  padding: ${({ $size }) => `${$size * 0.25}px`};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f3f4;
  transition: all 150ms ease;
  cursor: pointer;
  &:hover {
    background-color: #e7e7e9;
  }
`;

const CloseIcon = styled(FontAwesomeIcon)<{
  $size: number;
  $iconStyle?: Style;
}>`
  height: ${({ $size }) => `${$size}px`};
  width: ${({ $size }) => `${$size}px`};
  ${({ $iconStyle }) => $iconStyle};
`;

type Props = {
  size?: number;
  iconStyle?: Style; // custom icon styling
  onClick: () => void;
};

function CloseButton({
  size = 20,
  iconStyle,
  onClick,
  ...rest
}: Props): JSX.Element {
  return (
    <IconWrapper $size={size} onClick={onClick} {...rest}>
      <CloseIcon $size={size} $iconStyle={iconStyle} icon={faTimes} size="2x" />
    </IconWrapper>
  );
}

export default CloseButton;
