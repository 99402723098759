import './utils/wdyr';

import monkeypatch from './utils/monkeypatch';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { getEnvironment } from 'lib/environments';

import './styles/index.scss';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import StoreProvider from './store/Store';

// Dev imports - don't let this get too big!
import { StateInspector } from 'reinspect';

monkeypatch();

Sentry.init({
  dsn: 'https://78a5bbddafad4b8d966197fbbafce266@o1018090.ingest.sentry.io/6175437',
  integrations: [new Integrations.BrowserTracing()],
  environment: getEnvironment(),
  tracesSampleRate: 0.25,
});

ReactDOM.render(
  <React.StrictMode>
    {process.env.REACT_APP_ENABLE_REDUX_INSPECT === 'yes' ? (
      <StateInspector name="App">
        <StoreProvider>
          <App />
        </StoreProvider>
      </StateInspector>
    ) : (
      <StoreProvider>
        <App />
      </StoreProvider>
    )}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
