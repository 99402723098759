import { useState, ChangeEvent, useEffect } from 'react';

import styles from '../../styles/components/shared/Checkbox.module.scss';

interface ICheckboxProps {
  id: string;
  label: string;
  checked: boolean;
  onChange?: (e: boolean, id: string, cb: () => void) => void;
  onMouseEnter?: React.MouseEventHandler<HTMLLabelElement>;
}

export default function Checkbox({
  id,
  label,
  checked,
  onChange,
  onMouseEnter,
}: ICheckboxProps) {
  // "isChecked" (state) does not update even when "checked" (props) changes.
  // The solution in class components is to use getDerivedStateFromProps().
  // In functional components, it is through useEffect (see below).
  const [isChecked, setIsChecked] = useState(checked || false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newVal = e.target.checked;

    onChange && onChange(newVal, id, () => setIsChecked(newVal));
  };

  useEffect(() => {
    if (checked) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [checked]);

  return (
    <label onMouseEnter={onMouseEnter || undefined}>
      <div className={styles.checkboxContainer}>
        <input
          type="checkbox"
          id={id}
          className={styles.hiddenCheckbox}
          onChange={(e) => handleChange(e)}
          checked={isChecked}
        />
        <div
          className={`${styles.checkbox} ${isChecked ? styles.checked : ''}`}
        >
          <svg className={styles.checkmark} viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </svg>
        </div>
      </div>
      {label}
    </label>
  );
}
