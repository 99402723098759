export type ToolTipElement =
  | 'SEARCHBOX'
  | 'VESSEL_MARKER'
  | 'SIDEBAR_DELETE_BUTTON'
  | 'SIDEBAR_CLOSE_BUTTON'
  | 'SIDEBAR_VESSEL_HEADER';

export type ToolTipState = 'hover' | 'selected';

export type ToolTips = {
  [key in ToolTipElement]: {
    [key in ToolTipState]?: string;
  };
};

const DEFAULT_TOOLTIPS: string[] = [
  'Hold Shift and drag to select multiple vessels',
  'Click Set Up Flotilla to customize your experience',
];

export const TOOLTIPS: ToolTips = {
  SEARCHBOX: {
    hover: 'Click to search for vessels and actions',
    selected: 'Enter a search - try Singapore!',
  },
  VESSEL_MARKER: {
    hover: 'Click the vessel to see more details',
    selected: 'Select Crew Change to plan a crew change',
  },
  SIDEBAR_DELETE_BUTTON: {
    hover: 'Remove a vessel from the sidebar',
  },
  SIDEBAR_VESSEL_HEADER: {
    hover: 'Click to show/hide vessel details',
  },
  SIDEBAR_CLOSE_BUTTON: {
    hover: 'Close sidebar',
  },
};

const TOOLTIP_CHANGE_PERIOD_MS = 1000 * 30; // Change every 30 seconds

export function getToolTip(
  element?: ToolTipElement,
  state?: ToolTipState
): string {
  if (!element || !state) {
    return DEFAULT_TOOLTIPS[
      Math.floor(Date.now() / TOOLTIP_CHANGE_PERIOD_MS) %
        DEFAULT_TOOLTIPS.length
    ];
  }

  let tooltip = TOOLTIPS[element][state];

  if (!tooltip)
    return DEFAULT_TOOLTIPS[
      Math.floor(Date.now() / TOOLTIP_CHANGE_PERIOD_MS) %
        DEFAULT_TOOLTIPS.length
    ];
  else return tooltip;
}
