import { getAPIUrl } from 'frontend-library/dist/utils/platform';
import {
  checkAuthFailed,
  getAuthToken,
  AuthResponse,
} from 'frontend-library/dist/utils/auth';
import * as Sentry from '@sentry/react';

import {
  Vessel,
  UserInfo,
  ReminderResponse,
  AISEngineVessel,
  ApiResponse,
} from 'utils/types';
import { CrewChangeEvent } from 'utils/types/crew-change-types';
import { VesselsRequestBody } from 'frontend-library/dist/types/ais-engine';

interface VesselResponse extends AuthResponse {
  rows: Vessel[];
}

let logoutCallbackIdCtr = 0;
let onLogoutCallbacks: Map<number, () => void> = new Map();

export function onLogout(cb: () => void): number {
  const id = logoutCallbackIdCtr++;
  onLogoutCallbacks.set(id, cb);
  return id;
}

export function removeOnLogout(id: number) {
  onLogoutCallbacks.delete(id);
}

function fireLogout() {
  onLogoutCallbacks.forEach((v) => {
    try {
      v();
    } catch (e) {
      console.error(e);
    }
  });
}
const handleInvalidStatus = (response: Response) => {
  if (response.status < 200 || response.status >= 300) {
    throw new Error('Invalid response code.');
  }
};

export const checkIsIntercomLoaded = (): Promise<boolean> =>
  new Promise(async (resolve) => {
    let intercomLoaded = false;
    try {
      const res = await fetch(
        'https://api-iam.intercom.io/messenger/web/ping',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: `app_id=${process.env.REACT_APP_INTERCOM_APP_ID}`,
        }
      );
      intercomLoaded = res.status === 200;
    } catch (err) {
      console.error('error while checking whether intercom is loaded', err);
    }

    if (
      !intercomLoaded &&
      window.location.host.toLowerCase() === 'v2.grey-wing.com'
    ) {
      Sentry.captureEvent({
        message: 'Intercom is not loaded in production',
        level: Sentry.Severity.Warning,
      });
    }

    resolve(intercomLoaded);
  });

export const getFlotillaVessels: () => Promise<Map<
  number,
  Vessel
> | null> = async () => {
  const vesselsEndpoint = `${getAPIUrl()}/api/flotillav2/vessels`;

  const authToken = getAuthTokenValue();

  if (!authToken) {
    return null;
  }

  try {
    const res = await fetch(vesselsEndpoint, {
      method: 'POST',
      headers: headers(authToken),
    });
    const vessels: VesselResponse = await res.json();
    console.log('Loaded vessels - ', vessels.rows?.length);

    if (!checkAuthFailed(vessels)) {
      const vesselsObj: Map<number, Vessel> = new Map();
      vessels.rows.forEach((vessel) => {
        vesselsObj.set(vessel.id, vessel);
      });
      return vesselsObj;
    } else {
      return null;
    }
  } catch (err) {
    console.error('Error fetching vessels - ', err);
    return null;
  }
};

type AISVesselResponse = ApiResponse & {
  data?: Map<number, AISEngineVessel>;
};

export const getAISVessels: (
  body: VesselsRequestBody
) => Promise<AISVesselResponse> = async (body) => {
  const endpoint = `${getAPIUrl()}/api/flotillav2/ais-engine/vessels`;

  const authToken = getAuthTokenValue();

  if (!authToken) {
    return { success: false, message: 'Unauthorized.' };
  }

  try {
    const res = await fetch(endpoint, {
      method: 'POST',
      headers: headers(authToken),
      body: JSON.stringify(body),
    });

    const response: { success: boolean; vessels: AISEngineVessel[] } =
      await res.json();
    console.log('Loaded AIS vessels - ', response.vessels?.length);
    if (response.vessels) {
      const vesselsObj: Map<number, AISEngineVessel> = new Map();

      response.vessels!.forEach((vessel) => {
        vesselsObj.set(vessel.id, vessel as AISEngineVessel);
      });

      console.log('Vessels', vesselsObj);
      return { success: true, message: 'fetched', data: vesselsObj };
    } else {
      return { success: false, message: 'Unauthorized.' };
    }
  } catch (err) {
    console.error('Error fetching vessels - ', err);
    return { success: false, message: (err as any).message };
  }
};

export const getAirports = async (query: string) => {
  const airportsEndpoint = `${getAPIUrl()}/api/flotillav2/airports?term=${query}`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return null;
  }

  try {
    const response = await fetch(airportsEndpoint, {
      method: 'POST',
      headers: headers(authToken),
    });
    const homeAirports = await response.json();
    return homeAirports;
  } catch (error) {
    console.error('Error fetching crew home airports - ', error);
    return null;
  }
};

export const getVesselCrewChangeEvents = async (
  vesselId: number,
  withPastEvents?: boolean
): Promise<CrewChangeEvent | null> => {
  const endPoint = `${getAPIUrl()}/api/flotillav2/vessels/${vesselId}/crewChangeEvents`;
  const query = withPastEvents ? '?withPastEvents=true' : '';
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return null;
  }

  try {
    const response = await fetch(`${endPoint}${query}`, {
      method: 'GET',
      headers: headers(authToken),
    });
    const crewChangeEvents = await response.json();

    if (!checkAuthFailed(crewChangeEvents)) {
      return crewChangeEvents;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching crew change events - ', error);
    return null;
  }
};

export const getUserInfo = async (): Promise<{
  success: boolean;
  message: string;
  userConfig?: UserInfo;
}> => {
  const userInfoEndpoint = `${getAPIUrl()}/api/v2/users/config`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Please login.',
    };
  }

  try {
    const response = await fetch(userInfoEndpoint, {
      method: 'GET',
      headers: headers(authToken),
    });
    // throw exception for invalid response code
    handleInvalidStatus(response);
    const userConfig = await response.json();
    return {
      success: true,
      message: 'User info retrieved successfully.',
      userConfig,
    };
  } catch (error) {
    console.log('Error fetching user config.', error);
    return {
      success: false,
      message: 'Failed to fetch user config.',
    };
  }
};

export const getAuthTokenValue = (): string | null => {
  const authToken = getAuthToken();

  if (authToken.success) {
    if (authToken.token) {
      return authToken.token;
    }

    fireLogout();
    Sentry.captureException(
      new Error('getAuthToken() returns success but no auth token present.')
    );
    return null;
  }

  fireLogout();
  return null;
};

const headers = (token: string) => {
  return {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
};

export type ReminderStatusType = 'current' | 'upcoming';

export const getReminders = async (
  page: number,
  size: number = 10,
  reminderStatus?: ReminderStatusType | undefined
): Promise<ReminderResponse> => {
  const reminder = `${getAPIUrl()}/api/flotillav2/reminders?page=${page}&size=${size}${
    reminderStatus ? `&reminderStatus=${reminderStatus}` : ''
  }`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Please login.',
    };
  }

  try {
    const res = await fetch(reminder, {
      method: 'GET',
      headers: headers(authToken),
    });
    return {
      success: true,
      message: 'success',
      reminders: await res.json(),
    };
  } catch (error) {
    return {
      success: false,
      message: 'Error fetching reminders - ' + error,
    };
  }
};

export const deleteReminder = async (
  reminderId: string
): Promise<{ success: boolean; message: string }> => {
  const reminder = `${getAPIUrl()}/api/flotillav2/reminders/${reminderId}`;
  const authToken = getAuthTokenValue();

  if (!authToken) {
    return {
      success: false,
      message: 'Please login.',
    };
  }

  try {
    await fetch(reminder, {
      method: 'DELETE',
      headers: headers(authToken),
    });

    return {
      success: true,
      message: 'success',
    };
  } catch (error) {
    return {
      success: false,
      message: 'Error removing reminder - ' + error,
    };
  }
};
