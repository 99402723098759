type Props = {
  label?: string;
  emoji?: string;
  size?: number;
};

function Emoji({ label, emoji, size = 24 }: Props): JSX.Element | null {
  return emoji ? (
    <span
      role="img"
      style={{ fontSize: size }}
      aria-label={label || ''}
      aria-hidden={label ? 'false' : 'true'}
    >
      {emoji}
    </span>
  ) : null;
}

export default Emoji;
