import Fuse from 'fuse.js';
import { AuthResponse } from 'frontend-library/dist/utils/auth';
import { LngLatBoundsLike } from 'mapbox-gl';

import { ToolTipElement, ToolTipState } from './tooltips';
import { UserNotificationEvent } from './types/notification-event-types';
import { VesselFieldHighlight } from './highlight-vessels';
import { VesselsRequestBody } from 'frontend-library/dist/types/ais-engine';

export const FLOTILLA_CONFIG_STATE_KEY = 'flotillaConfig';

export interface Point {
  lat: number;
  lng: number;
}

export interface BBox {
  minLat: number;
  maxLat: number;
  minLng: number;
  maxLng: number;
}

export interface PixelBBox {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
}

export interface Airport {
  id: string;
  lat: number;
  lon: number;
  text: string;
}

export interface HomeAirports {
  [k: string]: {
    count: number;
    offsigners: number;
    onsigners: number;
    airport: Airport;
  };
}

export interface Nationalities {
  [k: string]: {
    count: number;
    offsigners: number;
    onsigners: number;
    country: {
      id: string;
      text: string;
    };
  };
}
export interface Vessel {
  [key: string]:
    | number
    | string
    | Date
    | boolean
    | HomeAirports
    | Nationalities;
  id: number;
  imo: string;
  riskStatusColor: string;
  course: string;
  lat: number;
  lng: number;
  name: string;
  departure: Date;
  nextPortName: string;
  nextPortCode: string;
  lastPortName: string;
  lastPortCode: string;
  routeExists: boolean;
  owner: string;
  manager: string;
  arrival: Date;
  operatingStatus: string;
  secondsToNextPort: number;
  selected: boolean;
  crewHomeAirports: HomeAirports;
  crewNationalities: Nationalities;
  routeUpdatedAt: Date;
  updatedAt: string;
  speed: number;
  addedBy: string;
  status: string;
  flag: string;
  type: string;
  picLabel: string;
  picId: number;
}

export type ToolTipSetter = DebouncedFunc<
  (element?: ToolTipElement, state?: ToolTipState) => void
>;

export interface Route extends AuthResponse {
  updated: Date;
  path: {
    pathGeoJSON: any;
  } | null;
  waypoints: Array<Waypoint>;
  properties?: {
    isClient: boolean;
  };
}

export interface PortCallResponse extends AuthResponse {
  meta?: any;
  portCalls: AnyPortCall[];
  success: boolean;
  message: string;
}

export interface Waypoint {
  currentPosition?: boolean;
  eta?: Date;
  etd?: Date;
  hoursInPort?: number;
  id: string;
  lat: number;
  lon: number;
  text: string;
}

export interface PortCall {
  text: string;
  id: string;
  lat: number;
  lng: number;
  eta?: string;
  etd?: string;
}

export type AnyPortCall = IMOSPortCall | PredictivePortCall | AISPortCall;

export type PortCallMeta = {
  purpose: string;
  voyageStatus?: string;
  function?: string;
};
export interface IMOSPortCall extends PortCall {
  type: 'Voyage Plan';
  locode: string;
  source: 'IMOS';
  meta?: PortCallMeta;
}

export interface PredictivePortCall extends PortCall {
  type: 'Predictive';
  locode?: string;
  source: 'Commercial';
}

export interface AISPortCall extends PortCall {
  type: 'AIS';
  locode: string;
  source: 'AIS';
}

export interface RouteState {
  routes: Map<number, Route>;
  portCalls: Map<number, PortCallResponse>;
}

export interface RouteMethods {
  storeVesselRoute: (vesselId: number, route: Route) => void;
  storeVesselPortCalls: (vesselId: number, portCalls: PortCallResponse) => void;
}

export type RouteStore = RouteState & RouteMethods;

export type RoutesAction =
  | {
      type: 'STORE_VESSEL_PORT_CALLS';
      payload: {
        vesselId: number;
        portCalls: PortCallResponse;
      };
    }
  | {
      type: 'STORE_VESSEL_ROUTE';
      payload: {
        vesselId: number;
        route: Route;
      };
    };

export interface FlotillaMapState {
  loaded?: boolean;
  vesselsLoaded?: boolean;
  vesselSelected?: Vessel;
}

export interface UnrealizedSearchResult {
  searchFieldName: string;
  searchFieldDescription: string;
  priority: number;
  searchResultDescFn: (count: number, term: string) => string;
  filterLabel: string;
  results: () => Promise<{
    timeTaken: number;
    results: Fuse.FuseResult<any>[];
  }>;
}

export interface VesselSearchResult {
  field: {
    [key: VesselFieldId]: VesselFieldValue | null;
    idField: number;
  };
  match?: string;
}

export interface SearchResult {
  searchFieldName: string;
  searchFieldDescription: string;
  priority: number;
  searchResultDescFn: (count: number, term: string) => string;
  filterLabel: string;
  results: { timeTaken: number; results: VesselSearchResult[] };
}

export interface Update {
  id: number;
  created_at: Date;
  updated_at: Date;
  prevValue: string;
  newValue: string;
  updateType: string;
  field: string;
  initiator: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  } | null;
  content: {
    short: string;
    long: string;
  };
  entity: {
    type: string;
    id: string;
    name: string;
  };
}

export interface NotificationUpdate {
  id: number;
  created_at: Date;
  updated_at: Date;
  update_id: number;
  recipient_id: number;
  web_notification_read_at: Date | null;
  remind_at: Date | null;
  update: Update;
}

export enum SidePanelContentType {
  VESSELS,
  UPDATES,
}

export interface SidePanelSettings {
  visible: boolean;
  vessels: number[];
  updates: NotificationUpdate[];
  contentType: SidePanelContentType | null;
  hideVesselRoute: number[];
}

export enum LoadingState {
  NONE,
  LOADING,
  LOADED,
}

export interface ReminderTag {
  text?: string;
  type?: ReminderTagType;
  meta?: {};
}

export type ReminderTagType = 'vessel' | 'port' | 'generic';

export interface Reminder {
  remindOn: string;
  text: string;
  subtext: string | '';
  shouldNotify: boolean;
  tags: ReminderTag[];
}

// TODO: replace Reminder when merged
export interface Reminderv2 {
  remindOn: string;
  text: string;
  subtext: string | '';
  shouldNotify: boolean;
  tags: ReminderTagObject[];
}

export interface ReminderTagObject extends ReminderTag {
  id: string;
  createdAt: Date;
  reminderId: string;
}
export interface ReminderObject extends Reminderv2 {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  creatorId: string;
  notified: boolean;
}

export interface PaginatedReminders extends Pagination {
  reminders: ReminderObject[];
}

export interface ApiResponse {
  success: boolean;
  message: string;
}

export interface ReminderResponse {
  success: boolean;
  message: string;
  reminders?: PaginatedReminders;
}

export interface Pagination {
  count: number;
  currentPage: number;
  totalPages: number;
  pageSize: number;
}
export interface ProximityPort {
  distanceMetres: number;
  id: number;
  locode: string;
  description: string;
  name: string;
  added_by: string | null;
  lat: number;
  lng: number;
  created_at: Date;
  country: {
    country: {
      name: string;
      'alpha-2': string;
      'country-code': number;
    };
    portsOpen: string;
    visas: {};
  };
  costs: {
    s5: {
      indicative: number;
    };
  };
  pcrTestInfo: {
    locode: string;
    crew_change_possible: boolean;
    advance_notice_days_min: number;
    advance_notice_days_max: number;
    onsigner_pcr_required: string;
    onsigner_quarantine_upon_arrival: boolean;
    pcr_results_hrs_min: number;
    pcr_results_hrs_max: number;
    pcr_test_availability: boolean;
  };
}

export interface InfoboxPopup {
  type: 'vessel';
  data: AISEngineVessel;
}

export type MapRulerType = 'simple' | 'route';
export type MapRulerUnits = 'kilometers' | 'miles' | 'nauticalmiles';
export interface MapRulerState {
  type: MapRulerType;
  distance: number;
  units: MapRulerUnits;
}
export interface FlotillaState {
  mapRuler: MapRulerState | null;
  infoboxPopup: InfoboxPopup | null;
  isReady: {
    vessel: boolean;
    route: boolean;
    map: boolean;
    vesselIcons: boolean;
  };
  searchParams: VesselsRequestBody | null;
  AISVesselsFull: Map<number, AISEngineVessel>;
  AISVesselsFullUpdatedAt: string | Date | null;
  vesselsFull: Map<number, Vessel>;
  vesselsFullUpdatedAt: string | Date | null;
  vessels: Map<number, Vessel>;
  vesselsUpdatedAt: string | Date | null;
  sidePanel: SidePanelSettings;
  mapState: FlotillaMapState;
  currentRouteVesselId: number | null;
  currentRoute: Route | null;
  currentZoomedVessel: number | null;
  searchId: string | null;
  searchQuery: string | null;
  updateInput: boolean;
  searchReady: boolean;
  searchResults: SearchResult[];
  searchResultsExpanded: boolean[];
  searchIsLoading: boolean;
  searchIsExpanded: boolean;
  searchResultFocused: number | null;
  toolTipText: string;
  showWizard: boolean;
  showReminderForm: boolean;
  rangeNauticalMiles: number;
  showPortsInRange: boolean;
  portsInRange: ProximityPort[];
  highlightVessels: number[];
}

interface SetMapRulerTypeAction {
  type: 'SET_MAP_RULER_TYPE';
  payload: MapRulerType;
}

interface SetMapRulerDistanceAction {
  type: 'SET_MAP_RULER_DISTANCE';
  payload: number;
}

interface StartMapRulerAction {
  type: 'START_MAP_RULER';
  payload: MapRulerState;
}

interface SetInfoboxAction {
  type: 'SET_INFOBOX_POPUP';
  payload: InfoboxPopup;
}

interface MapIsReadyAction {
  type: 'MAP_READY';
  payload: {
    type: string;
    isReady: boolean;
  };
}

interface HideSidePanelVesselRouteAction {
  type: 'HIDE_SIDE_PANEL_VESSEL_ROUTE';
  payload: number;
}

interface UnhideSidePanelVesselRouteAction {
  type: 'UNHIDE_SIDE_PANEL_VESSEL_ROUTE';
  payload: number;
}
interface NoPayloadAction {
  type:
    | 'CLOSE_SIDE_PANEL'
    | 'SEARCH_READY'
    | 'CLEAR_SIDE_PANEL_VESSELS'
    | 'RESET_FLOTILLA_STATE'
    | 'CLEAR_INFOBOX_POPUP'
    | 'CLEAR_MAP_RULER';
}

interface OpenSidePanelAction {
  type: 'OPEN_SIDE_PANEL';
  payload: SidePanelContentType;
}

interface PopulateVesselAction {
  type: 'POPULATE_VESSELS';
  payload: Map<number, Vessel>;
}
interface PopulateAISVesselAction {
  type: 'POPULATE_AIS_VESSELS';
  payload: Map<number, AISEngineVessel>;
}

interface PopulateFilteredVesselsAction {
  type: 'POPULATE_FILTERED_VESSELS';
  payload: Map<number, Vessel>;
}

interface ToggleVesselsToSidePanelAction {
  type: 'TOGGLE_VESSELS_TO_SIDE_PANEL';
  payload: number[];
}

interface AddVesselsToSidePanelAction {
  type: 'ADD_VESSELS_TO_SIDE_PANEL';
  payload: number[];
}

interface FlotillaSearchAction {
  type: 'FLOTILLA_SEARCH';
  payload: {
    searchId: string;
    searchQuery: string;
    searchIsLoading: boolean;
    searchResults: SearchResult[];
    updateInput: boolean;
  };
}

interface RemovePillAction {
  type: 'REMOVE_PILL';
  payload: number;
}

interface SetTooltipTextAction {
  type: 'SET_TOOLTIP_TEXT';
  payload: {
    element?: ToolTipElement;
    state?: ToolTipState;
  };
}

interface SetMapStateAction {
  type: 'SET_MAP_STATE';
  payload: FlotillaMapState;
}

interface ExpandResultCardAction {
  type: 'COLLAPSE_RESULT_CARD';
  payload: number;
}
interface CollapseResultCardAction {
  type: 'EXPAND_RESULT_CARD';
  payload: number;
}

interface SetSearchExpandedAction {
  type: 'SET_SEARCH_EXPANDED';
  payload: boolean;
}

interface SetSearchResultFocusedAction {
  type: 'FOCUS_SEARCH_RESULT';
  payload: number | null;
}

interface ShowWizardAction {
  type: 'SHOW_WIZARD';
}

interface HideWizardAction {
  type: 'HIDE_WIZARD';
}

interface UnsetUpdateInputAction {
  type: 'UNSET_UPDATE_INPUT';
  payload: null;
}

interface SetHighlightVesselsAction {
  type: 'SET_HIGHLIGHT_VESSELS';
  payload: number[];
}

interface SetSearchParamsAction {
  type: 'SET_SEARCH_PARAMS';
  payload: VesselsRequestBody;
}

export type FlotillaAction =
  | SetSearchParamsAction
  | HideSidePanelVesselRouteAction
  | UnhideSidePanelVesselRouteAction
  | SetMapRulerTypeAction
  | SetMapRulerDistanceAction
  | StartMapRulerAction
  | SetInfoboxAction
  | MapIsReadyAction
  | PopulateVesselAction
  | PopulateAISVesselAction
  | OpenSidePanelAction
  | ToggleVesselsToSidePanelAction
  | AddVesselsToSidePanelAction
  | FlotillaSearchAction
  | RemovePillAction
  | SetTooltipTextAction
  | NoPayloadAction
  | SetMapStateAction
  | SetSearchExpandedAction
  | SetSearchResultFocusedAction
  | ShowWizardAction
  | HideWizardAction
  | UnsetUpdateInputAction
  | SetHighlightVesselsAction
  | PopulateFilteredVesselsAction
  | ExpandResultCardAction
  | CollapseResultCardAction;

export interface FlotillaSearchArg {
  searchId: string;
  searchQuery: string;
  updateInput: boolean;
  vessels?: Map<number, Vessel>;
}

export type VesselResponse = {
  success: boolean;
  message: string;
  id?: number;
  vessel?: { name: string };
};

export interface FlotillaDispatches {
  setSearchParams: (body: VesselsRequestBody) => void;
  setMapRulerType: (type: MapRulerType) => void;
  setMapRulerDistance: (distance: number) => void;
  startMapRuler: ({
    type,
    units,
  }: {
    type: MapRulerType;
    units: MapRulerUnits; // This is not being used right now, still using global routeUnits config
  }) => void;
  clearMapRuler: () => void;
  setInfoboxPopup: ({
    type,
    data,
  }: {
    type: 'vessel';
    data: AISEngineVessel;
  }) => void;
  clearInfoboxPopup: () => void;
  setMapReady: (type: string, v: boolean) => void;
  setShowWizard: () => void;
  setHideWizard: () => void;
  fetchAISVessels: (props: VesselsRequestBody) => Promise<boolean>;
  populateFilteredVessels: (vessels: Map<number, Vessel>) => void;
  addVesselsToSidePanel: (v: number[]) => void;
  toggleVesselsToSidePanel: (v: number[]) => void;
  clearSidePanelVessels: () => void;
  openFlotillaSidePanel: (contentType: SidePanelContentType) => void;
  closeFlotillaSidePanel: () => void;
  hideSidePanelVesselRoute: (v: number) => void;
  unhideSidePanelVesselRoute: (v: number) => void;
  removePill: (id: number) => void;
  flotillaSearch: (v: FlotillaSearchArg) => void;
  setSearchReady: () => void;
  setToolTipText: ToolTipSetter;
  setMapState: (v: FlotillaMapState) => void;
  setSearchExpanded: (v: boolean) => void;
  setSearchResultFocused: (v: number | null) => void;
  unsetUpdateInput: () => void;
  setHighlightVessels: (vesselIds: number[]) => void;
  expandResultCard: (idx: number) => void;
  collapseResultCard: (idx: number) => void;
  resetFlotillaState: () => void;
}

export interface SequentialParameters<T extends VesselFieldValue> {
  min: T;
  max: T;
}

export interface QualitativeParameters<T extends VesselFieldValue> {
  buckets: Array<T>;
}

export interface HighlightParameters<T extends VesselFieldValue> {
  field: string;
  emptyCheck: (value: any) => boolean;
  emptyExample: T | null;
  sequential: SequentialParameters<T> | null;
  qualitative: QualitativeParameters<T> | null;
}

export interface VesselFieldHighlightConfig {
  highlightType: 'enum' | 'number' | 'date';
  // Type of highlight.
  colorPalette: 'sequential' | 'qualitative';
  // Type of color palette to use.
}

export interface VesselFieldSearchConfig<T> {
  searchPriority: number;
  // Priority in how search is dispatched and displayed. Lower is better.
  searchFieldDescription: string;
  // Description of the search field.
  indexTransformer?(value: T): any | any[];
  // Transformed for the index, to combine values into a format accessible to the search function.
}

export type PrintableVesselField<T> = {
  displayPriority: number;
  // Lower is better, order in which fields are displayed.
  getDisplayString(value: T): string;
  // Get an (unstyled for native fields) string for this value
  shortDesc: string;
  // Short description for the field. e.g. "Name"
  longDesc: string;
  // Long description for the field. e.g. "Vessel Name"
  emptyValues: any[];
  // Values considered invalid/empty for this field.
  emptyValueReplacementStr: string;
  // Values to replace empty values with for purposes like display.
};

export interface LabelFieldMetadata extends PrintableVesselField<Vessel> {}

export interface CompoundPopupFieldMetadata
  extends PrintableVesselField<Vessel> {
  fieldInputs: VesselFieldId[];
  compoundField: true;
}

export type CompoundSidePanelField = {
  displayPriority: number;
  persistent: boolean;
  shortDesc: string;
  longDesc: string;
  getDisplayElement(vessel: AISEngineVessel): JSX.Element | null;
};

export type CompoundSidePanelFields = {
  [key: string]: CompoundSidePanelField;
};

export interface VesselFieldMetadata<T extends VesselFieldValue>
  extends PrintableVesselField<T> {
  display: boolean;
  // Can this field be displayed (anywhere) at all?
  popupEnabled: boolean;
  // Can this field be shown on the popup?
  fieldType: 'enum' | 'number' | 'string' | 'date' | 'object';
  // Type of field used for sorting/categorization.
  command: string | null;
  // starting a query with this followed by a colon only searches this field
  commandDescription: string | null;
  // Text to display to the user telling them what to type after the command
  includedInCSV: boolean;
  // Whether this field is included in the CSV.
  updateFrequencyMs: number | null;
  // Expected update frequency of this field, for fractional refreshes. Null means will not change.
  highlightConfig?: VesselFieldHighlightConfig;
  // configuration for marker highlighting based on this field.  Empty means cannot be highlighted.
  searchConfig?: VesselFieldSearchConfig<T>;
  // Configuration for indexing and searching this field. Empty means cannot be indexed.
  processFieldFromString?(inp: string): T;
  // Function to process a string (received from the server to the correct type.)
  emptyValues: any[];
  // Values considered invalid/empty for this field.
  emptyValueReplacementStr: string;
  // Values to replace empty values with for purposes like display.
  countDescFunc(count: number, term: string, representative?: boolean): string;
  // Describe unit(s) of this field value. If representative is set to true, generates a label which will not contain count and term.
}

export type MapRef = React.MutableRefObject<MapObject | null | undefined>;

export interface MapObject {
  map: mapboxgl.Map;
  focusOnVessel: (vessel: Vessel) => void;
  focusOnPort: (coordinates: { lat: number; lon: number }) => void;
  remove: () => void;
  zoomToVessels: (v: Vessel[]) => void;
  makeVesselBig: (v: Vessel | null) => void;
  setVesselMap: (v: Map<number, Vessel>) => void;
}

export type VesselFieldId = keyof VesselFieldsMetadata & string;
export type AISVesselFieldId = keyof AISVesselFieldsMetadata & string;

export type VesselFieldValue =
  | Vessel
  | number
  | string
  | null
  | Date
  | HomeAirports
  | Nationalities
  | boolean;

export type VesselFieldsMetadata = {
  [key: string]: VesselFieldMetadata<VesselFieldValue>;
  id: VesselFieldMetadata<number>;
  name: VesselFieldMetadata<string>;
  imo: VesselFieldMetadata<string>;
  mmsi: VesselFieldMetadata<string>;
  nextPortCode: VesselFieldMetadata<string>;
  // lastPortCode: VesselFieldMetadata<string>;
  nextPortName: VesselFieldMetadata<string>;
  // lastPortName: VesselFieldMetadata<string>;
  lng: VesselFieldMetadata<number>;
  lat: VesselFieldMetadata<number>;
  // owner: VesselFieldMetadata<string>;
  // manager: VesselFieldMetadata<string>;
  updatedAt: VesselFieldMetadata<Date>;
  arrival: VesselFieldMetadata<Date>;
  // departure: VesselFieldMetadata<Date>;
  image: VesselFieldMetadata<string>;
  speed: VesselFieldMetadata<number>;
  dwt: VesselFieldMetadata<number>;
  course: VesselFieldMetadata<number>;
  status: VesselFieldMetadata<string>;
  source: VesselFieldMetadata<string>;
  flag: VesselFieldMetadata<string>;
  type: VesselFieldMetadata<string>;
  destinationLat: VesselFieldMetadata<number | null>;
  destinationLng: VesselFieldMetadata<number | null>;
};

export type AISVesselFieldsMetadata = {
  [key: string]: VesselFieldMetadata<VesselFieldValue>;
  id: VesselFieldMetadata<number>;
  name: VesselFieldMetadata<string>;
  imo: VesselFieldMetadata<string>;
  mmsi: VesselFieldMetadata<string>;
  nextPortCode: VesselFieldMetadata<string>;
  lng: VesselFieldMetadata<number>;
  lat: VesselFieldMetadata<number>;
  updatedAt: VesselFieldMetadata<Date>;
  course: VesselFieldMetadata<number>;
  arrival: VesselFieldMetadata<Date>;
  type: VesselFieldMetadata<string>;
  status: VesselFieldMetadata<string>;
  dwt: VesselFieldMetadata<number>;
  nextPortName: VesselFieldMetadata<string>;
  flag: VesselFieldMetadata<string>;
  source: VesselFieldMetadata<string>;
  destinationLat: VesselFieldMetadata<number>;
  destinationLng: VesselFieldMetadata<number>;
  lastPortCode: VesselFieldMetadata<string>;
  speed: VesselFieldMetadata<number>;
  departure: VesselFieldMetadata<Date>;
  owner: VesselFieldMetadata<string>;
  manager: VesselFieldMetadata<string>;
};

export interface SearchFilter {
  query: string;
  field: AISVesselFieldId;
  label: string;
  exclude: boolean;
}

export type UserInfo = {
  id: number;
  email: string | null;
  company_id: number | null;
  firstname: string | null;
  lastname: string | null;
  phone: string | null;
};

export type FlotillaConfigState = {
  // Field we're using to color highlight the vessels.
  colorHighlightField: string | null;
  /** Fields displayed in the popup. Both VesselFieldId and CompoundPopupField fields are allowed. */
  popupFields: string[];
  /** Fields displayed in the sidepanel. Both VesselFieldId and CompoundSidePanelField fields are allowed. */
  sidePanelFields: string[];
  /** Field used to show a label on the vessel. If empty, no labels are shown. */
  vesselLabelField: string;
  /** Search filters. */
  searchFilters: SearchFilter[];
  /** Map config. Writes are cached to localStorage, but do not update the app except at runTime. */
  map: {
    bounds: LngLatBoundsLike | null;
  };
  userInfo?: UserInfo;
  userNotificationEvents: UserNotificationEvent[];
};
interface FlotillaConfigNoPayloadAction {
  type: 'CLEAR_SEARCH_FILTERS';
}

export type FlotillaConfigAction =
  | FlotillaConfigNoPayloadAction
  | {
      type: 'LOAD_CONFIG';
      payload: FlotillaConfigState;
    }
  | {
      type: 'SET_USER_INFO';
      payload: UserInfo;
    }
  | {
      type: 'SET_USER_NOTIFICATION_EVENTS';
      payload: UserNotificationEvent[];
    }
  | {
      type: 'UPDATE_COLOR_HIGHLIGHT_FIELD';
      payload: string | null;
    }
  | {
      type: 'UPDATE_POPUP_FIELDS';
      payload: string[];
    }
  | {
      type: 'UPDATE_SIDEPANEL_FIELDS';
      payload: string[];
    }
  | {
      type: 'UPDATE_LABEL_FIELD';
      payload: string;
    }
  | {
      type: 'UPDATE_MAP_BOUNDS';
      payload: LngLatBoundsLike;
    }
  | {
      type: 'ADD_SEARCH_FILTER';
      payload: SearchFilter;
    }
  | {
      type: 'REMOVE_SEARCH_FILTER';
      payload: number; // index of the filter in the array in the state
    };

export interface FlotillaConfigDispatches {
  loadConfig: (config: FlotillaConfigState) => void;
  setUserInfo: (info: UserInfo) => void;
  setUserNotificationEvents: (events: UserNotificationEvent[]) => void;
  updateColorHighlightField: (v: string | null) => void;
  updatePopUpFields: (v: string[]) => void;
  updateSidePanelFields: (v: string[]) => void;
  updateLabelField: (v: string) => void;
  updateMapBounds: (v: LngLatBoundsLike) => void;
  addSearchFilter: (filter: SearchFilter) => void;
  removeSearchFilter: (index: number) => void;
  clearSearchFilters: () => void;
}

/** Utilities */

export interface DebouncedFunc<T extends (...args: any[]) => any> {
  /**
   * Call the original function, but applying the debounce rules.
   *
   * If the debounced function can be run immediately, this calls it and returns its return
   * value.
   *
   * Otherwise, it returns the return value of the last invocation, or undefined if the debounced
   * function was not invoked yet.
   */
  (...args: Parameters<T>): ReturnType<T> | undefined;

  /**
   * Throw away any pending invocation of the debounced function.
   */
  cancel(): void;

  /**
   * If there is a pending invocation of the debounced function, invoke it immediately and return
   * its return value.
   *
   * Otherwise, return the value from the last invocation, or undefined if the debounced function
   * was never invoked.
   */
  flush(): ReturnType<T> | undefined;
}

export type HandlerThunk = () => Promise<any>;

export type HighlightedField = {
  field: string;
  highlight: VesselFieldHighlight<any>;
};

//############### AIS Engine #####################

// Don't know how this works, take the time to understand later
// We're basically trying to remove the index signature from Vessel
// type KnownKeys<T> = {
//   [K in keyof T]: string extends K ? never : number extends K ? never : K;
// } extends { [_ in keyof T]: infer U }
//   ? U
//   : never;
// type RemoveIndex<T extends Record<any, any>> = Pick<T, KnownKeys<T>>;

type RemoveIndex<T> = {
  [K in keyof T as string extends K
    ? never
    : number extends K
    ? never
    : K]: T[K];
};

export interface AISEngineVessel
  extends Omit<
    RemoveIndex<Vessel>,
    | 'riskStatusColor'
    | 'routeExists'
    | 'operatingStatus'
    | 'secondsToNextPort'
    | 'selected'
    | 'crewHomeAirports'
    | 'crewNationalities'
    | 'routeUpdatedAt'
    | 'addedBy'
    | 'picLabel'
    | 'picId'
    | 'crewChangeEvent'
    // disabled/edited fields
    | 'arrival'
    | 'lastPortName'
    | 'lastPortCode'
    | 'speed'
    | 'departure'
    | 'owner'
    | 'manager'
  > {
  [key: string]: null | number | string;
  // disabled for now, might re-enable once we have more data and upgrades
  lastPortName: null;
  lastPortCode: null;
  speed: null;
  departure: null;
  owner: null;
  manager: null;

  // edited
  arrival: string;

  // new fields
  mmsi: number;
  dwt: string | null;
  source: string;
  destinationLat: number | null;
  destinationLng: number | null;
}
