import { v4 as uuidv4 } from 'uuid';

let sessionId: string | null;

export const trackUserAction = (eventId: string, options?: any) => {
  import('frontend-library/dist/utils/amplitude').then((amplitude) => {
    const { ActivityType, logUserActivity } = amplitude;
    logUserActivity(ActivityType.click, eventId, options);
  });
};

export const trackUserSession = () => {
  sessionId = sessionId || uuidv4();
  if (document.visibilityState === 'visible') {
    trackUserAction('session-started:v2-flotilla', {
      sessionId,
      time: new Date().toISOString(),
    });
  }

  if (document.visibilityState === 'hidden') {
    trackUserAction('session-ended:v2-flotilla', {
      sessionId,
      time: new Date().toISOString(),
    });
    sessionId = null;
  }
};
