import { ReactNode } from 'react';
import { Card } from 'components/shared';

const CardContainer = ({
  children,
  style,
  isLoading,
}: {
  children: ReactNode;
  style?: React.CSSProperties;
  isLoading?: boolean;
}) => (
  <Card
    isLoading={isLoading}
    style={{
      borderRadius: 0,
      ...style,
    }}
  >
    {children}
  </Card>
);

export default CardContainer;
