import { ETA_SEARCH_KEY } from 'lib/constants';
import { COMMAND_REGEX } from 'utils/search-engine';
import { vesselFields } from 'utils/vesselFields';

import { UserInfo, Vessel } from 'utils/types';

export function getUserLabel(userInfo: UserInfo) {
  let userData = [];

  if (userInfo.firstname) userData.push(userInfo.firstname);
  if (userInfo.lastname) userData.push(userInfo.lastname);

  if (userInfo.lastname || userInfo.firstname)
    userData.push(`(${userInfo.email})`);
  else userData.push(userInfo.email);

  return userData.join(' ');
}

// filter vessels for ETA search
export function filterETASearchVessels(
  term: string,
  vessels: Map<number, Vessel>
) {
  const commandMatch = term.match(COMMAND_REGEX);
  const command = commandMatch?.length ? commandMatch[1] : null;

  const query = !command || !commandMatch ? term : commandMatch[2];

  const selectedTime = Number(query.trim());
  let results: Vessel[] = [];
  let timeTaken = performance.now();

  vessels.forEach((v) => {
    if (v.secondsToNextPort && v.secondsToNextPort < selectedTime) {
      results.push(v);
    }
  });

  const searchFieldId = ETA_SEARCH_KEY;
  const vesselField = vesselFields[searchFieldId];

  return [
    {
      searchFieldName: searchFieldId,
      searchFieldDescription:
        vesselField.searchConfig?.searchFieldDescription || '',
      searchResultDescFn: vesselField.countDescFunc,
      filterLabel: vesselField.countDescFunc(0, String(selectedTime), true),
      priority: 1,
      results: async () => {
        return {
          results: results
            .sort((a, b) => a.secondsToNextPort - b.secondsToNextPort)
            .map((vessel) => ({
              item: {
                idField: vessel.id,
                [searchFieldId]: vessel[searchFieldId],
              },
              matches: [],
            })),
          timeTaken: performance.now() - timeTaken,
        };
      },
    },
  ];
}
