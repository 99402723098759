export const ETA_SEARCH_KEY = 'secondsToNextPort';
export const ETA_SEARCH_COMMAND = 'eta';
export const CREW_CHANGE_CANCEL_MESSAGE =
  "Are you sure? You'll lose the crew change planning progress.";
export const ADDED_CREWS = 'addedCrews';
export const SIDE_PANEL_DEFAULT_EXCLUSION = [
  'compound_crewNationalities',
  'compound_crewHomeAirports',
];
export const NM_TO_KM = 1.852;

// CC panel heights
export const CC_PANEL_INITIAL_HEIGHT = '40vh';
export const CC_PANEL_COLLAPSED_HEIGHT = '68px';
