import { trackUserAction } from 'lib/amplitude';
import { FlotillaAction, FlotillaConfigAction } from '../utils/types';
import { SidePanelAction } from '../utils/sidepanel-types';

// import * as Sentry from "@sentry/react";

//TODO:  This is a temporary fix, we need to build a better one when we're building in payloads
// We also have no type checking
const eventExclusionList = [
  'LOAD_CONFIG',
  'STORE_CONFIG',
  'SET_NEW_VESSEL_LOADING',
  'SET_NEW_VESSEL_ERROR',
  'ZOOM_TO_VESSEL',
  'UNSET_UPDATE_INPUT',
  'FOCUS_SEARCH_RESULT',
  'SET_MAP_STATE',
  'SET_TOOLTIP_TEXT',
  'LOAD_ROUTE',
  'SET_SEARCH_EXPANDED',
  'ERASE_CURRENT_ROUTE',
  'POPULATE_FILTERED_VESSELS',
  'POPULATE_VESSELS',
  'SEARCH_READY',
];

export const sendEvent = (
  action: FlotillaAction | FlotillaConfigAction | SidePanelAction
) => {
  // these trigger very often
  if (eventExclusionList.includes(action.type)) {
    return;
  }
  trackUserAction(action.type);
};

/**
 * TODO: V2, send payload for certain events. Amplitude only accepts plain objects as payload but
 * our dispatch payloads can be one of a wide variety of types (some even with functions).
 * Need to handle them on a case-by-case basis.
 * The following function does not work perfectly. I might even consider rewriting it.
 */

// export const sendEvent = (action: FlotillaAction | FlotillaConfigAction) => {
//   try {
//     if (!("payload" in action) || action.payload === undefined || action.payload === null) {
//       logUserEvent(action.type);
//       return;
//     }

//     // these trigger very often
//     if ((action.type === 'SET_SEARCH_EXPANDED' && action.payload === false) || action.type === 'SET_TOOLTIP_TEXT') {
//       return;
//     }

//     const amplitudePayload: any = {};

//     if (typeof action.payload === "boolean") {
//       amplitudePayload.booleanValue = action.payload;
//     } else if (typeof action.payload === "string") {
//       amplitudePayload.stringValue = action.payload;
//     } else if (typeof action.payload === "number") {
//       amplitudePayload.numberValue = action.payload;
//     } else if (action.payload instanceof Map) {
//       amplitudePayload.ids = Array.from(action.payload.keys());
//     } else if (action.payload instanceof Array) {
//       amplitudePayload.arrayValues = action.payload;
//     } else if (action.payload instanceof Object) {
//       amplitudePayload.objectValue = action.payload;
//     } else {
//       Sentry.captureEvent({
//         message: `No handler for payload for action ${action.type}, payload ${action.payload}. Sending amplitude events without payload.`,
//         level: Sentry.Severity.Warning
//       });
//     }

//     logUserEvent(action.type, amplitudePayload);

//   } catch (err) {
//     console.error("Error sending event to amplitude", err);
//   }
// }
