export const vesselHighlights = {
  baseStyle: {
    strokeColor: '#424343',
    strokeWeight: 1,
    fillOpacity: 0.9,
  },
  highlightFields: ['fillColor'],
  empty: '#385DEA',
  sequential: [
    '#881e19',
    '#b9330B',
    '#db6f02',
    '#f5b402',
    '#aad11d',
    '#69a129',
    '#387f35',
    '#106033',
  ],
  qualitative: [
    '#2629e3',
    '#39b030',
    '#f8524f',
    '#b71518',
    '#fca636',
    '#f57a00',
    '#003e1f',
    '#4b3f72',
  ],
};

export function interpolate(
  startColorHex: string,
  endColorHex: string,
  fraction: number
): string | null {
  fraction = Math.max(fraction, 0);
  fraction = Math.min(fraction, 1);

  const start = hexToRgb(startColorHex);
  const end = hexToRgb(endColorHex);

  if (!start || !end) {
    console.error('unable to interpolate colors, ', arguments);
    return null;
  }

  const newColor = {
    r: Math.round((end.r - start.r) * fraction + start.r),
    g: Math.round((end.g - start.g) * fraction + start.g),
    b: Math.round((end.b - start.b) * fraction + start.b),
  };

  return rgbToHex(newColor.r, newColor.g, newColor.b);
}

export interface RGB {
  r: number;
  g: number;
  b: number;
}

function hexToRgb(hex: string): RGB | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function rgbToHex(r: number, g: number, b: number): string {
  function componentToHex(c: number) {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}
