import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import styled from 'styled-components/macro';

import { textBlack } from 'lib/colors';
import Button from './Button';
import CloseButton from './CloseButton';

const TitleWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0 20px;
`;

const Title = styled.h2`
  color: ${textBlack};
  margin-bottom: 20px;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledButton = styled(Button)`
  height: 25px;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  margin: 0;
`;

const getModalStyle = (width?: number) => ({
  style: {
    border: 'none',
    borderRadius: '8px',
    minWidth: `${width ? `${width}px` : '500px'}`,
    maxHeight: '75vh',
  },
});

type Props = {
  width?: number;
  title?: string;
  actions?: React.ReactNode;
  children: React.ReactNode;
  closeModal?: () => void;
};

function Modal({
  width,
  title,
  children,
  actions,
  closeModal,
}: Props): JSX.Element {
  const renderFooter = () => {
    if (actions) {
      return <DialogActions sx={{ py: 2, px: 3 }}>{actions}</DialogActions>;
    }
    return (
      closeModal && (
        <DialogActions sx={{ py: 2, px: 3 }}>
          <Footer>
            <StyledButton variant="primary" onClick={closeModal}>
              Close
            </StyledButton>
          </Footer>
        </DialogActions>
      )
    );
  };

  return (
    <Dialog
      open
      scroll="paper"
      onClose={closeModal}
      PaperProps={getModalStyle(width)}
    >
      <TitleWrapper>
        {Boolean(title) && <Title>{title}</Title>}
        {closeModal && <CloseButton size={24} onClick={closeModal} />}
      </TitleWrapper>

      <DialogContent dividers sx={{ py: 3 }}>
        {children}
      </DialogContent>

      {renderFooter()}
    </Dialog>
  );
}

export default Modal;
