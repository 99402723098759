import Lottie from 'react-lottie';
import styled from 'styled-components/macro';

const Title = styled.div`
  font-size: 1.1rem;
  font-weight: bold;
  line-spacing: 0.05rem;
  margin: 3rem auto;
  text-align: center;
`;

type Props = { title?: string; data: any };

function SuccessAnimation({ title, data }: Props) {
  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet',
    },
  };

  return (
    <div id="completion-animation">
      <Lottie options={animationOptions} width={'20em'} />
      {title && <Title>{title}</Title>}
    </div>
  );
}

export default SuccessAnimation;
