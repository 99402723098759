export const MAIN_VESSELS_SOURCE_ID = 'all-vessel';
export const MAIN_VESSELS_LAYER_ID_SMALL = `${MAIN_VESSELS_SOURCE_ID}-small`;
export const MAIN_VESSELS_LAYER_ID_LARGE = `${MAIN_VESSELS_SOURCE_ID}-large`;
// export const MAIN_VESSELS_FEATURE_ID = null; // a number making use of the vessel id.

export const MAIN_ROUTES_SOURCE_ID = 'all-vessel-routes';
export const MAIN_ROUTES_FEATURE_ID = 'vessel-route-'; // this is combined with the vessel id.

export const ACTIVE_VESSELS = 'active-vessels';
export const ACTIVE_ROUTES = 'active-routes';
export const ACTIVE_WAYPOINTS = 'active-waypoints';

export const ACTIVE_VESSEL_FEATURE = 'active-vessel-';
export const ACTIVE_VESSEL_ROUTE_FEATURE = 'active-vessel-route-';

export const ACTIVE_PORT_WAYPOINTS = 'active-port-waypoints';
export const SELECTED_PORT_WAYPOINTS = 'selected-port';
