import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const IconWrapper = styled(FlexWrapper)`
  margin-right: 10px;
  height: 26px;
  width: 26px;
  padding: 5px;
  border-radius: 6px;
  justify-content: center;
  background-color: #f3f3f4;
  transition: all 150ms ease;
  cursor: pointer;
  &:hover {
    background-color: #e7e7e9;
  }
`;
const StyledIcon = styled(FontAwesomeIcon)`
  height: 20px;
  width: 20px;
`;

type Props = {
  collapse: boolean;
  onClick: (arg: any) => void;
};

function CollapseButton({ collapse, onClick }: Props) {
  return (
    <IconWrapper onClick={onClick}>
      {!collapse ? (
        <StyledIcon icon={faCaretDown} size="2x" />
      ) : (
        <StyledIcon icon={faCaretUp} size="2x" />
      )}
    </IconWrapper>
  );
}

export default CollapseButton;
