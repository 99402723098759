import { createContext, ReactNode, useRef, useState } from 'react';

type SidePanelFieldContextValue = {
  activeRefs: any;
  highlightedField?: string;
  setHighlightedField: React.Dispatch<React.SetStateAction<any>>;
  isInWizard: boolean;
};

export const SidePanelFieldContext = createContext<SidePanelFieldContextValue>({
  activeRefs: {},
  highlightedField: undefined,
  setHighlightedField: () => {},
  isInWizard: false,
});

export const SidePanelFieldProvider = ({
  children,
  isInWizard = false,
}: {
  children: ReactNode;
  isInWizard?: boolean;
}) => {
  const activeRefs = useRef<any>({});
  const [highlightedField, setHighlightedField] = useState<
    string | undefined
  >();
  return (
    <SidePanelFieldContext.Provider
      value={{
        activeRefs,
        highlightedField,
        setHighlightedField,
        isInWizard,
      }}
    >
      {children}
    </SidePanelFieldContext.Provider>
  );
};
