import { AISEngineVessel, CompoundSidePanelFields } from '../../utils/types';

import MiscData from './MiscData';
import VesselInfo from './VesselInfo';

export const compoundSidePanelFields: CompoundSidePanelFields = {
  compound_vesselBasicInfo: {
    displayPriority: 2,
    persistent: true,
    shortDesc: 'Vessel Info',
    longDesc: 'Highlighted Vessel Information',
    getDisplayElement: (vessel: AISEngineVessel) => (
      <VesselInfo vessel={vessel} />
    ),
  },
  compound_miscData: {
    displayPriority: 57,
    persistent: true,
    shortDesc: 'Misc Data',
    longDesc: 'Miscellaneous Data',
    getDisplayElement: (vessel: AISEngineVessel) => (
      <MiscData vessel={vessel} />
    ),
  },
};
