import React from 'react';

if (process.env.REACT_APP_ENABLE_WHY_RENDER === 'yes') {
  try {
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
      trackAllPureComponents: true,
      trackHooks: true,
    });
  } catch (err) {
    console.error('COULD NOT LOAD WDYR. Are you in the right mode?');
  }
  console.log('Inspect mode: Enabling why did you render...');
}
