import { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import sleep from 'lib/sleep';

type Props = {
  value: string;
  options: string[];
  capitalize?: boolean;
  fontSize?: string;
  formStyles?: { [key: string]: string | number };
  onChange: (e: SelectChangeEvent) => void;
};

function Dropdown({
  value,
  options,
  fontSize,
  capitalize,
  formStyles = {},
  onChange,
}: Props) {
  const [open, setOpen] = useState(false);
  const textStyles = {
    fontSize: fontSize || '0.9rem',
    fontFamily: 'HK Grotesk, Roboto',
  };

  return (
    <FormControl variant="standard" sx={formStyles}>
      <Select
        size="small"
        open={open}
        value={value}
        defaultValue={value}
        onChange={onChange}
        onOpen={() => {
          // add delay to fix keyboard navigation issue
          sleep(100).then(() => setOpen(true));
        }}
        onClose={() => setOpen(false)}
        sx={textStyles}
      >
        {options.map((text, index) => (
          <MenuItem key={`${text}_${index}`} value={text} sx={textStyles}>
            {capitalize ? text.toUpperCase() : text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default Dropdown;
