import debounce from 'lodash/debounce';
import { useReducer as standardUseReducer } from 'react';
import { useReducer as inspectableUseReducer } from 'reinspect';

import { ETA_SEARCH_COMMAND } from 'lib/constants';
import {
  SearchResult,
  SidePanelContentType,
  VesselSearchResult,
  MapRulerType,
  MapRulerUnits,
  AISEngineVessel,
} from 'utils/types';
import { VesselsRequestBody } from 'frontend-library/dist/types/ais-engine';

// APIs
import { getAISVessels } from 'api/flotilla';
import { searchVessels } from 'utils/search-engine';

import { getToolTip } from 'utils/tooltips';
import { DisplayConfig, FlotillaStoreConfig } from 'utils/flotilla-config';

// types
import {
  FlotillaAction,
  FlotillaState,
  FlotillaDispatches,
  FlotillaSearchArg,
  FlotillaMapState,
  Vessel,
} from 'utils/types';

import { filterETASearchVessels } from './helpers';
import { sendEvent } from '../utils';
import { uniq } from 'lodash';

// STORE ACTIONS
export const createFlotillaDispatches: (
  f: (a: FlotillaAction) => void
) => FlotillaDispatches = (flotillaDispatch) => ({
  setSearchParams: (params: VesselsRequestBody) => {
    flotillaDispatch({
      type: 'SET_SEARCH_PARAMS',
      payload: params,
    });
  },

  setMapRulerType: (type: MapRulerType) => {
    flotillaDispatch({
      type: 'SET_MAP_RULER_TYPE',
      payload: type,
    });
  },

  setMapRulerDistance: (distance: number) => {
    flotillaDispatch({
      type: 'SET_MAP_RULER_DISTANCE',
      payload: distance,
    });
  },

  clearMapRuler: () => {
    flotillaDispatch({
      type: 'CLEAR_MAP_RULER',
    });
  },

  startMapRuler: ({
    type,
    units,
  }: {
    type: MapRulerType;
    units: MapRulerUnits;
  }) => {
    flotillaDispatch({
      type: 'START_MAP_RULER',
      payload: {
        type,
        units,
        distance: 0,
      },
    });
  },

  clearInfoboxPopup: () => {
    flotillaDispatch({
      type: 'CLEAR_INFOBOX_POPUP',
    });
  },

  setInfoboxPopup: ({
    type,
    data,
  }: {
    type: 'vessel';
    data: AISEngineVessel;
  }) => {
    flotillaDispatch({
      type: 'SET_INFOBOX_POPUP',
      payload: {
        type,
        data,
      },
    });
  },

  setMapReady: (type: string, isReady: boolean) => {
    flotillaDispatch({
      type: 'MAP_READY',
      payload: {
        type,
        isReady,
      },
    });
  },

  setShowWizard: function () {
    flotillaDispatch({
      type: 'SHOW_WIZARD',
    });
  },

  setHideWizard: function () {
    flotillaDispatch({
      type: 'HIDE_WIZARD',
    });
  },

  fetchAISVessels: async (props: VesselsRequestBody) => {
    try {
      const { success, data } = await getAISVessels(props);
      if (success) {
        flotillaDispatch({
          type: 'POPULATE_AIS_VESSELS',
          payload: data!,
        });

        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  },

  setSearchReady: () => {
    flotillaDispatch({
      type: 'SEARCH_READY',
    });
  },

  setMapState: (mapState: FlotillaMapState) => {
    flotillaDispatch({
      type: 'SET_MAP_STATE',
      payload: mapState,
    });
  },

  toggleVesselsToSidePanel: (vessels: number[]) => {
    flotillaDispatch({
      type: 'TOGGLE_VESSELS_TO_SIDE_PANEL',
      payload: vessels,
    });
  },

  addVesselsToSidePanel: (vessels: number[]) => {
    flotillaDispatch({
      type: 'ADD_VESSELS_TO_SIDE_PANEL',
      payload: vessels,
    });
  },

  clearSidePanelVessels: () => {
    flotillaDispatch({
      type: 'CLEAR_SIDE_PANEL_VESSELS',
    });
  },

  openFlotillaSidePanel: (contentType: SidePanelContentType) => {
    flotillaDispatch({
      type: 'OPEN_SIDE_PANEL',
      payload: contentType,
    });
  },

  hideSidePanelVesselRoute: (v: number) => {
    flotillaDispatch({
      type: 'HIDE_SIDE_PANEL_VESSEL_ROUTE',
      payload: v,
    });
  },

  unhideSidePanelVesselRoute: (v: number) => {
    flotillaDispatch({
      type: 'UNHIDE_SIDE_PANEL_VESSEL_ROUTE',
      payload: v,
    });
  },

  populateFilteredVessels: (vessels: Map<number, Vessel>) => {
    flotillaDispatch({
      type: 'POPULATE_FILTERED_VESSELS',
      payload: vessels,
    });
  },

  closeFlotillaSidePanel: () => {
    flotillaDispatch({
      type: 'CLOSE_SIDE_PANEL',
    });
  },

  removePill: (id: number) => {
    flotillaDispatch({
      type: 'REMOVE_PILL',
      payload: id,
    });
  },

  setSearchResultFocused: (id: number | null) => {
    flotillaDispatch({
      type: 'FOCUS_SEARCH_RESULT',
      payload: id,
    });
  },

  unsetUpdateInput: () => {
    flotillaDispatch({
      type: 'UNSET_UPDATE_INPUT',
      payload: null,
    });
  },

  expandResultCard: (idx: number) => {
    flotillaDispatch({
      type: 'EXPAND_RESULT_CARD',
      payload: idx,
    });
  },

  collapseResultCard: (idx: number) => {
    flotillaDispatch({
      type: 'COLLAPSE_RESULT_CARD',
      payload: idx,
    });
  },

  flotillaSearch: async ({
    searchId,
    searchQuery,
    updateInput,
    vessels,
  }: FlotillaSearchArg) => {
    flotillaDispatch({
      type: 'FLOTILLA_SEARCH',
      payload: {
        searchId,
        searchQuery,
        updateInput,
        searchResults: [],
        searchIsLoading: true,
      },
    });

    let searchResults =
      // use custom helper to filter vessels for ETA search
      searchQuery.includes(ETA_SEARCH_COMMAND) && vessels
        ? filterETASearchVessels(searchQuery, vessels)
        : await searchVessels(searchQuery);
    const realizedResults: SearchResult[] = [];
    const nRows = searchResults.length;
    for (let i = 0; i < nRows; i++) {
      realizedResults.push({
        ...searchResults[i],
        results: { timeTaken: 0, results: [] },
      });
    }

    for (let rowId = 0; rowId < nRows; rowId++) {
      const rIn = await searchResults[rowId].results();
      for (const result of rIn.results) {
        let resultItem: VesselSearchResult = {
          field: result.item,
          match:
            (result.matches?.length && result.matches[0].value) || undefined,
        };
        realizedResults[rowId].results.results.push(resultItem);
      }
    }
    flotillaDispatch({
      type: 'FLOTILLA_SEARCH',
      payload: {
        searchId,
        searchQuery,
        updateInput,
        searchResults: realizedResults,
        searchIsLoading: false,
      },
    });
  },

  setToolTipText: debounce(function (element, state) {
    flotillaDispatch({
      type: 'SET_TOOLTIP_TEXT',
      payload: {
        element,
        state,
      },
    });
  }, FlotillaStoreConfig.tooltipDebounceMs),

  setSearchExpanded: function (v: boolean) {
    flotillaDispatch({
      type: 'SET_SEARCH_EXPANDED',
      payload: v,
    });
  },

  setHighlightVessels: function (vesselIds: number[]) {
    flotillaDispatch({
      type: 'SET_HIGHLIGHT_VESSELS',
      payload: vesselIds,
    });
  },

  resetFlotillaState: function () {
    flotillaDispatch({
      type: 'RESET_FLOTILLA_STATE',
    });
  },
});

// REDUCER
const initialFlotillaState: FlotillaState = {
  mapRuler: null,
  infoboxPopup: null,
  isReady: {
    vessel: false,
    route: false,
    map: false,
    vesselIcons: false,
  },
  searchParams: null,
  AISVesselsFull: new Map(),
  AISVesselsFullUpdatedAt: null,
  vesselsFull: new Map(),
  vesselsFullUpdatedAt: null,
  vessels: new Map(),
  vesselsUpdatedAt: null,
  sidePanel: {
    visible: false,
    vessels: [],
    updates: [],
    contentType: null,
    hideVesselRoute: [],
  },
  mapState: {
    loaded: false,
    vesselsLoaded: false,
  },
  currentRouteVesselId: null,
  currentRoute: null,
  currentZoomedVessel: null,
  searchId: '',
  searchQuery: '',
  searchReady: true, // by default this is now ready. buliding an index isn't necessary anymore
  updateInput: false,
  searchResults: [],
  searchResultsExpanded: [],
  searchIsLoading: false,
  searchResultFocused: null,
  toolTipText: getToolTip(),
  searchIsExpanded: false,
  showWizard: false,
  showReminderForm: false,
  showPortsInRange: false,
  portsInRange: [],
  rangeNauticalMiles: 100,
  highlightVessels: [],
};

const reducer: (
  a: FlotillaState | undefined,
  b: FlotillaAction
) => FlotillaState = (state = initialFlotillaState, action: FlotillaAction) => {
  let updatedSidePanelVessels: number[];
  let sidePanelIds: Set<number>;

  sendEvent(action);

  switch (action.type) {
    case 'SET_SEARCH_PARAMS':
      return {
        ...state,
        searchParams: action.payload,
      };
    case 'SET_MAP_RULER_TYPE':
      if (!state.mapRuler) return state;
      return {
        ...state,
        mapRuler: {
          ...state.mapRuler,
          type: action.payload,
        },
      };

    case 'SET_MAP_RULER_DISTANCE':
      if (!state.mapRuler) return state;
      return {
        ...state,
        mapRuler: {
          ...state.mapRuler,
          distance: action.payload,
        },
      };

    case 'START_MAP_RULER':
      return {
        ...state,
        mapRuler: action.payload,
      };

    case 'CLEAR_MAP_RULER':
      return {
        ...state,
        mapRuler: null,
      };

    case 'SET_INFOBOX_POPUP':
      return {
        ...state,
        infoboxPopup: action.payload,
      };

    case 'CLEAR_INFOBOX_POPUP':
      return {
        ...state,
        infoboxPopup: null,
      };

    case 'MAP_READY':
      return {
        ...state,
        isReady: {
          ...state.isReady,
          [action.payload.type]: action.payload.isReady,
        },
      };

    case 'POPULATE_VESSELS':
      return {
        ...state,
        vesselsFull: action.payload,
        vesselsFullUpdatedAt: new Date(),
      };

    case 'POPULATE_AIS_VESSELS':
      return {
        ...state,
        AISVesselsFull: action.payload,
        AISVvesselsFullUpdatedAt: new Date(),
      };

    case 'POPULATE_FILTERED_VESSELS':
      return {
        ...state,
        vessels: action.payload,
        vesselsUpdatedAt: new Date(),
      };

    case 'TOGGLE_VESSELS_TO_SIDE_PANEL':
      sidePanelIds = new Set<number>(state.sidePanel.vessels);

      action.payload.forEach(function (v: number) {
        if (!sidePanelIds.has(v)) sidePanelIds.add(v);
        else sidePanelIds.delete(v);
      });

      return {
        ...state,
        sidePanel: {
          ...state.sidePanel,
          visible: !!(
            sidePanelIds.size &&
            window.innerWidth > DisplayConfig.minimumSidePanelDisplayWidthPx
          ),
          vessels: Array.from(sidePanelIds),
          contentType: SidePanelContentType.VESSELS,
        },
      };

    case 'ADD_VESSELS_TO_SIDE_PANEL':
      sidePanelIds = new Set<number>(state.sidePanel.vessels);

      action.payload.filter(Boolean).forEach((v: number) => {
        sidePanelIds.add(v);
      });

      return {
        ...state,
        sidePanel: {
          ...state.sidePanel,
          visible: !!(
            sidePanelIds.size &&
            window.innerWidth > DisplayConfig.minimumSidePanelDisplayWidthPx
          ),
          vessels: Array.from(sidePanelIds),
          contentType: SidePanelContentType.VESSELS,
        },
      };

    case 'OPEN_SIDE_PANEL':
      return {
        ...state,
        sidePanel: {
          ...state.sidePanel,
          visible: true,
          contentType: action.payload,
        },
      };

    case 'HIDE_SIDE_PANEL_VESSEL_ROUTE':
      const updatedArray = uniq([
        ...state.sidePanel.hideVesselRoute,
        action.payload,
      ]);
      return {
        ...state,
        sidePanel: {
          ...state.sidePanel,
          hideVesselRoute: updatedArray,
        },
      };

    case 'UNHIDE_SIDE_PANEL_VESSEL_ROUTE':
      const updatedUnhideArray = state.sidePanel.hideVesselRoute.filter(
        (o) => o !== action.payload
      );
      return {
        ...state,
        sidePanel: {
          ...state.sidePanel,
          hideVesselRoute: updatedUnhideArray,
        },
      };

    case 'CLEAR_SIDE_PANEL_VESSELS':
      return {
        ...state,
        sidePanel: {
          ...state.sidePanel,
          vessels: [],
          hideVesselRoute: [],
        },
      };

    case 'CLOSE_SIDE_PANEL':
      return {
        ...state,
        sidePanel: {
          ...state.sidePanel,
          hideVesselRoute: [],
          visible: false,
        },
      };

    case 'EXPAND_RESULT_CARD': {
      const res = { ...state };
      res.searchResultsExpanded[action.payload] = true;
      return res;
    }
    case 'COLLAPSE_RESULT_CARD': {
      const res = { ...state };
      res.searchResultsExpanded[action.payload] = false;
      return res;
    }

    case 'SET_MAP_STATE':
      return {
        ...state,
        mapState: {
          ...state.mapState,
          ...action.payload,
        },
      };

    case 'REMOVE_PILL':
      updatedSidePanelVessels = state.sidePanel.vessels.filter(
        (vessel) => vessel !== action.payload
      );

      return {
        ...state,
        sidePanel: {
          ...state.sidePanel,
          vessels: updatedSidePanelVessels,
        },
      };

    case 'FLOTILLA_SEARCH':
      const {
        searchId,
        searchQuery,
        searchResults,
        searchIsLoading,
        updateInput,
      } = action.payload;

      return {
        ...state,
        searchResultFocused: null,
        searchId,
        searchQuery,
        searchResults,
        searchResultsExpanded: searchResults.map((_: any) => false),
        searchIsLoading,
        updateInput,
      };

    case 'UNSET_UPDATE_INPUT':
      return {
        ...state,
        updateInput: false,
      };

    case 'SEARCH_READY':
      if (!state.searchReady)
        return {
          ...state,
          searchReady: true,
        };
      else return state;

    case 'SET_TOOLTIP_TEXT':
      const newToolTipText = getToolTip(
        action.payload.element,
        action.payload.state
      );
      if (newToolTipText !== state.toolTipText)
        return {
          ...state,
          toolTipText: getToolTip(action.payload.element, action.payload.state),
        };
      else return state;

    case 'SET_SEARCH_EXPANDED':
      if (action.payload === state.searchIsExpanded) return state;
      return {
        ...state,
        searchIsExpanded: action.payload,
      };

    case 'FOCUS_SEARCH_RESULT':
      return {
        ...state,
        searchResultFocused: action.payload,
      };

    case 'SHOW_WIZARD':
      return {
        ...state,
        showWizard: true,
      };

    case 'HIDE_WIZARD':
      return {
        ...state,
        showWizard: false,
      };

    case 'SET_HIGHLIGHT_VESSELS':
      return {
        ...state,
        highlightVessels: Array.from(action.payload),
      };

    case 'RESET_FLOTILLA_STATE':
      return {
        ...initialFlotillaState,
      };

    default:
      return state;
  }
};

export const useFlotillaReducer = () => {
  if (process.env.REACT_APP_ENABLE_REDUX_INSPECT === 'yes') {
    return inspectableUseReducer(
      reducer,
      initialFlotillaState,
      (identity) => identity,
      'flotilla-store'
    );
  } else {
    return standardUseReducer(reducer, initialFlotillaState);
  }
};

if (process.env.REACT_APP_ENABLE_REDUX_INSPECT === 'yes')
  console.log('Inspect mode: Flotilla reducer is inspectable...');
