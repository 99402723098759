import Fuse from 'fuse.js';
import { ETA_SEARCH_KEY } from 'lib/constants';
import {
  Vessel,
  UnrealizedSearchResult,
  VesselFieldId,
  VesselFieldMetadata,
  VesselFieldValue,
  SearchFilter,
} from './types';

import { vesselFields } from './vesselFields';

const fuseInstances: { [key: string]: Fuse<any> } = {};
let indexRebuildPromise: Promise<boolean> | null = null;
let currentSearchTerm = null;

export const COMMAND_REGEX = /^\s*([^\s]+):(.*)/;

function getAllIndexableFields(): VesselFieldId[] {
  return Object.keys(vesselFields).filter(
    (fieldId) => !!vesselFields[fieldId as VesselFieldId].searchConfig
  ) as VesselFieldId[];
}

type VesselField = VesselFieldMetadata<VesselFieldValue>;

export function getAllCommands(): VesselField[] {
  return Object.values(vesselFields).filter((v) => !!v.command);
}

export function commandCompletion(
  query: string,
  equal: boolean = false
): VesselField[] {
  const commandMatch = query.match(COMMAND_REGEX);
  const commandQuery =
    !commandMatch || commandMatch.length < 2
      ? null
      : commandMatch[1].toLowerCase();
  const strippedQuery = (commandQuery || query).trim().toLowerCase();
  const res = new Set<VesselField>();
  for (const field of Object.values(vesselFields)) {
    const command = field.command;
    if (command === null) continue;
    if (equal) {
      if (command !== commandQuery) continue;
    } else {
      if (command === commandQuery) continue;
    }
    if (
      (commandQuery && command.startsWith(commandQuery)) ||
      command.startsWith(strippedQuery)
    )
      res.add(field);
  }
  return Array.from(res);
}

export function replaceCommand(query: string, newCommand: string): string {
  const matches = query.match(COMMAND_REGEX);
  if (!matches || matches.length < 2) {
    return `${newCommand}: `;
  } else {
    return `${newCommand}: ${matches[2]}`;
  }
}

const filterETAVesselIds = (vessels: Map<number, Vessel>, query: string) => {
  let vesselIds: number[] = [];
  vessels.forEach((v) => {
    if (v.secondsToNextPort && v.secondsToNextPort < Number(query.trim())) {
      vesselIds.push(v.id);
    }
  });
  return vesselIds;
};

/**
 * Applies a set of search filters to a vessel list by building an index that is discarded after application.
 * @param vessels Incoming vessels to be filtered.
 * @param filters List of filters to apply.
 * @returns Filtered Vessel map, on reference copy of vessels passed in.
 */
export function applyFilters(
  vessels: Map<number, Vessel>,
  filters: SearchFilter[]
): Map<number, Vessel> {
  if (!filters.length) {
    return vessels;
  }

  console.time('Applying filters to vessels');

  let filteredVesselIds: number[] = [];

  let fuseIndexes = filters.map((filter) =>
    buildFuseIndex(vessels, [filter.field])
  );

  filters.forEach((filter, index) => {
    let appliedVesselIds: number[] =
      filter.field === ETA_SEARCH_KEY
        ? filterETAVesselIds(vessels, filter.query)
        : fuseIndexes[index]
            .search(filter.query)
            .map((result) => result.item.idField);

    if (index === 0) filteredVesselIds = appliedVesselIds;
    else
      filteredVesselIds = filteredVesselIds.filter((vesselId) =>
        appliedVesselIds.includes(vesselId)
      );
  });

  let filteredVessels = new Map<number, Vessel>();

  filteredVesselIds.forEach((vesselId) => {
    let vessel = vessels.get(vesselId);

    if (vessel) filteredVessels.set(vesselId, vessel);
  });

  console.timeEnd('Applying filters to vessels');

  console.log(
    `remaining vessels after applying ${filters.length} filters:`,
    filteredVessels
  );

  return filteredVessels;
}

/**
 * Rebuilds/builds the vessel index for searching. Must be called when the vessels list changes.
 * Blocks all searchs until completed.
 *
 * @param {Flotilla.Vessels} vessels List of vessels to build an index.
 * @returns {Promise} Promise to track the completion of index rebuild.
 */
export function buildVesselsIndex(
  vessels: Map<number, Vessel>,
  setSearchReady?: () => void
) {
  return new Promise((resolve, reject) => {
    try {
      if (vessels.size < 1) {
        console.log('Empty vessels, not building index');
        if (setSearchReady) {
          setSearchReady();
        }
        return false;
      }

      console.time('Building index');

      getAllIndexableFields().forEach((indexedFieldId) => {
        fuseInstances[indexedFieldId] = buildFuseIndex(vessels, [
          indexedFieldId,
        ]);
      });

      console.timeEnd('Building index');
      if (setSearchReady) {
        setSearchReady();
      }
      resolve(true);
      indexRebuildPromise = null;
    } catch (err) {
      console.error('Error building vessels index - ', err);
      reject(err);
    }
  });
}

function buildFuseIndex(
  vessels: Map<number, Vessel>,
  fields: VesselFieldId[]
): Fuse<any> {
  let indexList = Array.from(vessels.keys())
    .map((vesselId: number) => {
      let indexRow: any = {};
      indexRow.idField = vesselId;

      const vessel: any = vessels.get(vesselId);

      fields.forEach((indexedFieldId) => {
        let vesselField = vesselFields[indexedFieldId];
        indexRow[indexedFieldId] = vesselField.searchConfig?.indexTransformer
          ? vesselField.searchConfig.indexTransformer(vessel[indexedFieldId])
          : vessel[indexedFieldId];
      });

      return indexRow;
    })
    .filter((row) => row !== null);

  return new Fuse<any>(indexList, {
    keys: fields,
    includeMatches: true,
    isCaseSensitive: false,
    includeScore: true,
    shouldSort: true,
    useExtendedSearch: false,
    ignoreLocation: true,
    threshold: 0,
  });
}

/**
 * Searches vessels using the index.
 *
 * @param {String} term Search term.
 * @param {Array[String]} fields List of fields to search from SEARCH_ENGINE_CONFIG.flotilla.indexedFields. Empty defaults to all available search fields.
 * @param {Boolean} startAll start searches right away? If true, results are returned as a promise. If false, results are returned as an async function that needs to be run.
 * @returns Array of search workers with the following metadata:
 *   searchFieldName: Unique id of search field.
 *   searchFieldDescription: Plain-text description of search field.
 *   searchResultDescFn: Function that accepts a term and returns a plain-text description of the results.
 *   priority: A priority number of this search field, lower is higher priority.
 *   results: An async function (to be run) or a Promise, that returns the results and the time taken (if the search succeeds).
 */

export async function searchVessels(
  term: string,
  inpFieldIds: VesselFieldId[] | null = null,
  startAll = true
) {
  try {
    if (indexRebuildPromise) {
      console.log('Index rebuilding - waiting...');
      currentSearchTerm = term;
      await indexRebuildPromise;
      if (currentSearchTerm !== term) {
        console.log("Search has changed since we've waited. Aborting..");
        return [];
      }
    }

    if (!term) {
      console.log('No search term specified');
      return [];
    }

    // No vessels, hence no index is built.
    if (Object.keys(fuseInstances).length === 0) {
      return [];
    }

    let searches: UnrealizedSearchResult[] = [];

    const commandMatch = term.match(COMMAND_REGEX);
    const command = commandMatch?.length ? commandMatch[1] : null;
    const query = !command || !commandMatch ? term : commandMatch[2];
    // if (command) console.log('command', command, query);

    const fields: VesselFieldId[] = inpFieldIds
      ? inpFieldIds.filter((fieldId) => !!vesselFields[fieldId].searchConfig)
      : getAllIndexableFields();

    fields.sort(
      (a, b) =>
        (vesselFields[a].searchConfig?.searchPriority || 999) -
        (vesselFields[b].searchConfig?.searchPriority || 999)
    );

    console.time('Search Dispatched');
    fields.forEach((searchFieldId) => {
      const vesselField = vesselFields[searchFieldId];

      if (command && vesselField.command !== command) return;

      searches.push({
        searchFieldName: searchFieldId,
        searchFieldDescription:
          vesselField.searchConfig?.searchFieldDescription || '',
        searchResultDescFn: vesselField.countDescFunc,
        filterLabel: vesselField.countDescFunc(0, '', true),
        priority: vesselField.searchConfig?.searchPriority || 0,
        results: async () => {
          let timeTaken = performance.now();
          try {
            let innerResults: Fuse.FuseResult<any>[];
            if (!fuseInstances[searchFieldId]) {
              console.error(
                'Fuse Individual instance missing for ',
                searchFieldId,
                ' - ',
                fuseInstances
              );
              innerResults = [];
            } else {
              const q: { [k: string]: string } = {};
              q[searchFieldId] = query.trim();
              innerResults = fuseInstances[searchFieldId].search(q);
            }
            timeTaken = performance.now() - timeTaken;
            return { results: innerResults, timeTaken };
          } catch (err) {
            console.error('Error searching ', searchFieldId, ' - ', err);
            return { results: [], timeTaken: performance.now() - timeTaken };
          }
        },
      });

      if (startAll) searches[searches.length - 1].results();
    });
    console.timeEnd('Search Dispatched');

    return searches;
  } catch (err) {
    console.error('Error dispatching searches - ', err);
    return [];
  }
}
