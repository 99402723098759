import React from 'react';
import * as Sentry from '@sentry/react';

import Emoji from './shared/Emoji';
import styles from '../styles/components/ErrorBoundary.module.scss';

type Props = {
  children?: React.ReactNode;
};

// using error boundary from Sentry
// https://docs.sentry.io/platforms/javascript/guides/react/components/errorboundary/

function ErrorBoundary({ children }: Props): JSX.Element {
  const goBack = () => {
    window.location.reload();
  };

  return (
    <Sentry.ErrorBoundary
      fallback={() => (
        <div className={styles.errorContainer}>
          <Emoji emoji="😦" size={48} />
          <div>
            You've hit a bug in our system. Our team has been alerted to this
            issue.
          </div>
          <div>
            Click here to{' '}
            <span className={styles.errorLink} onClick={goBack}>
              go back
            </span>
            .
          </div>
        </div>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}

export default ErrorBoundary;
