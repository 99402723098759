import animationData from '../assets/lotties/49514-container-ship.json';

export const AppConfig = {
  /** How often the vessel list is refreshed. */
  vesselsUpdatedIntervalMs: 1000 * 60 * 5,
};

export const FlotillaStoreConfig = {
  /** How long to wait before actioning tooltip changes, to prevent rerenders and flickering */
  tooltipDebounceMs: 500,
};

export const VesselFieldsConfig = {
  /** Default date display options */
  dateDisplayOptions: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  } as Intl.DateTimeFormatOptions,
};

export const DisplayConfig = {
  minimumSidePanelDisplayWidthPx: 600,
};

export const GenerateFlotillaMapConfig = {
  // TODO: See if we can't get this dynamically
  /** Precomputed width of sidebar */
  sidebarWidth: 576,
  debounceHoverEffectMs: 500,
  zoomLimitFrac: 0.1,
  maximumPopupFields: 5,
  debounceMapMouseOutMs: 250,
};

export const FlotillaMapConfig = {
  mapStatusCheckInterval: 500,
  animationOptions: {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet',
    },
  },
  loadingAnimationTimeoutMs: 5000,
  eraseRouteDebounceMs: GenerateFlotillaMapConfig.debounceHoverEffectMs,
  routeUnits: 'nauticalmiles', // or kilometers or miles
  aisSearchLimit: 10000,
  debounceSearchMs: 250,
};

export const SidePanelConfig = {
  infoCollapseTimingMs: 300,
  slideCancelLimitPercentage: 25,
  slideCancelFallbackMinPx: 50,
  slideStartStickinessPercentage: 5,
  subFieldCollapsibleTransitionTimeMs: 50,
};
