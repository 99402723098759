import mapboxgl, { LngLatBoundsLike } from 'mapbox-gl';

import generateFeatureCollection from './generate-feature-collection';
import { computeBBox } from './geo';
import haversine from './haversine';
import * as Sentry from '@sentry/react';

import {
  FlotillaMapState,
  ToolTipSetter,
  Vessel,
  MapObject,
  Route,
  Waypoint,
} from './types';

import { HoverAirport } from './sidepanel-types';
import moment from 'moment';
import debounce from 'lodash/debounce';

import { DebouncedFunc } from './types';

import turfLength from '@turf/length';
import turfAlong from '@turf/along';

import { GenerateFlotillaMapConfig } from './flotilla-config';

const layerConstants = {
  type: 'line',
  paint: {
    'line-color': '#385dea',
    'line-width': 8,
    'line-pattern': 'line-texture',
  },
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
};

//*********************************************************************** Types */

type MapMouseEvent = mapboxgl.MapMouseEvent & {
  features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
};

//*********************************************************************** Mutable State */

const vesselsOnMap: Map<number, Vessel> = new Map();

let selectActive = false;

let eraseRouteDebounce: DebouncedFunc<(vesselId?: number) => void> | undefined;
let closeWaypointPopupsFunc: () => void;

//*********************************************************************** Generic Utility Functions */

function formatDate(d?: Date) {
  if (!d) return 'unknown';

  return moment(d).format('DD MMM YYYY');
}

function loadImage(
  map: mapboxgl.Map,
  image: string,
  name: string,
  callback?: () => void
) {
  map.loadImage(image, (err, img) => {
    // this if-condition is inside loadImage()'s callback to prevent race condition
    if (map.hasImage(name)) {
      console.log('image already added', name);
      return;
    }

    if (err || !img) {
      console.log(err);
      return;
    }
    map.addImage(name, img);

    callback?.();
  });
}

//*********************************************************************** GIS Functions */

export function generateWaypointsGeoJSON(
  waypoints?: Waypoint[]
): GeoJSON.FeatureCollection<GeoJSON.Point> | null {
  if (!waypoints) return null;

  const now = new Date();

  return {
    type: 'FeatureCollection',
    features: waypoints.map((waypoint) => ({
      type: 'Feature',
      id: waypoint.id,
      properties: {
        icon:
          waypoint.eta && new Date(waypoint.eta).getTime() > now.getTime()
            ? 'arrival-wp-marker'
            : 'depart-wp-marker',
        eta: waypoint.eta,
        etd: waypoint.etd,
        hoursInPort: waypoint.hoursInPort,
        id: waypoint.id,
        text: waypoint.text,
        lat: waypoint.lat,
        lon: waypoint.lon,
      },
      geometry: {
        type: 'Point',
        coordinates: [waypoint.lon, waypoint.lat],
      },
    })),
  };
}

export function renderWaypointPopup(waypoint: Waypoint, index: number): string {
  return `<div class="waypoint-map-popup">
    <div class="waypoint-popup-title">Waypoint ${index}</div>
    <ul>
      <li class="location-item">${waypoint.text}</li>
      ${
        waypoint.eta
          ? `
        <li class="eta-etd">ETA</li>
        <li class="time-item">${formatDate(waypoint.eta)}</li>
      `
          : ''
      }
      ${
        waypoint.etd
          ? `
        <li class="eta-etd">ETD</li>
        <li class="time-item">${formatDate(waypoint.etd)}</li>
      `
          : ''
      }
    </ul>
  </div>`;
}

export function drawVesselRoute(
  map: mapboxgl.Map,
  route: Route | undefined | null,
  vesselId: number | undefined
) {
  const routeGeoJSON:
    | GeoJSON.FeatureCollection<
        GeoJSON.MultiLineString,
        GeoJSON.GeoJsonProperties
      >
    | undefined = route?.path?.pathGeoJSON
    ? generateFeatureCollection({
        geometry: route.path.pathGeoJSON,
      })
    : undefined;

  if (!routeGeoJSON) {
    if (map.getLayer('vessel-route')) map.removeLayer('vessel-route');
    if (map.getSource('vessel-route')) map.removeSource('vessel-route');

    if (map.getLayer('waypoints')) map.removeLayer('waypoints');
    if (map.getSource('waypoints')) map.removeSource('waypoints');

    map.fire('closeWaypointPopups');
    map.off('closeWaypointPopups', closeWaypointPopupsFunc);
  } else {
    try {
      if (map.getSource('vessel-route')) {
        (map.getSource('vessel-route') as mapboxgl.GeoJSONSource).setData(
          routeGeoJSON
        );
      } else {
        map.addSource('vessel-route', {
          type: 'geojson',
          data: routeGeoJSON,
        } as mapboxgl.GeoJSONSourceRaw);
      }

      if (!map.getLayer('vessel-route')) {
        map.addLayer(
          {
            id: 'vessel-route',
            source: 'vessel-route',
            ...layerConstants,
          } as mapboxgl.LineLayer,
          'vessels'
        );
      }

      const waypointsGeoJSONData = generateWaypointsGeoJSON(route?.waypoints);

      if (!waypointsGeoJSONData) return;

      if (!map.getSource('waypoints')) {
        map.addSource('waypoints', {
          type: 'geojson',
          data: waypointsGeoJSONData,
        });

        map.addLayer(waypointLayer({}, {}, 'waypoints'));
      } else {
        (map.getSource('waypoints') as mapboxgl.GeoJSONSource).setData(
          waypointsGeoJSONData
        );
      }

      const popups = route?.waypoints
        .filter((waypoint) => !waypoint.currentPosition)
        .slice(1)
        .map((waypoint, index) => {
          const popup = new mapboxgl.Popup({
            anchor: index % 2 === 0 ? 'right' : 'left',
            closeButton: false,
            closeOnClick: false,
            className: 'waypoint-mapbox-popup',
          });

          popup
            .setLngLat({
              lat: waypoint.lat,
              lng: waypoint.lon,
            })
            .setHTML(renderWaypointPopup(waypoint, index + 1))
            .addTo(map);

          return popup;
        });

      addPopupFocusEventListener('waypoint-mapbox-popup', vesselId);

      closeWaypointPopupsFunc = () => {
        popups?.forEach((p) => p.remove());
      };

      map.on('closeWaypointPopups', closeWaypointPopupsFunc);
    } catch (err) {
      Sentry.captureException(err);
      console.error('Error drawing route - ', err);
      drawVesselRoute(map, undefined, undefined); // Erase any drawn route
    }
  }
}

function canDrawAirportRoute(
  vesselCoords: { lat?: number; lng?: number },
  airportCoords: { lat?: number; lon?: number }
) {
  const validVesselCoords = Boolean(vesselCoords?.lat && vesselCoords?.lng);
  const validAirportCoords = Boolean(airportCoords?.lat && airportCoords?.lon);

  return validVesselCoords && validAirportCoords;
}

export function drawAirport(
  map: mapboxgl.Map,
  hoverAirport: HoverAirport | undefined | null
) {
  const airportsLayerAndSourceId = 'airports';
  const routeLayerAndSourceId = 'airport-routes';

  if (!hoverAirport) {
    if (map.getLayer(airportsLayerAndSourceId))
      map.removeLayer(airportsLayerAndSourceId);

    if (map.getLayer(routeLayerAndSourceId)) {
      map.removeLayer(routeLayerAndSourceId);
    }
  } else {
    const { airport, vesselId, vesselLatLng } = hoverAirport;

    if (canDrawAirportRoute(vesselLatLng, airport)) {
      drawVesselAirportRoute(
        map,
        routeLayerAndSourceId,
        vesselLatLng,
        airport,
        vesselId,
        airport?.id
      );
    }

    const geoJSON = airportGeoJSON(hoverAirport);
    if (!map.getSource(airportsLayerAndSourceId)) {
      map.addSource(airportsLayerAndSourceId, {
        type: 'geojson',
        data: geoJSON,
      });
    } else {
      (
        map.getSource(airportsLayerAndSourceId) as mapboxgl.GeoJSONSource
      ).setData(geoJSON);
    }

    if (!map.getLayer(airportsLayerAndSourceId)) {
      map.addLayer({
        id: airportsLayerAndSourceId,
        type: 'symbol',
        source: airportsLayerAndSourceId,
        layout: {
          'icon-image': ['get', 'icon'],
          'icon-allow-overlap': true,
          'text-allow-overlap': true,
          'icon-size': 0.5,
        },
      });
    }
  }
}

function drawVesselAirportRoute(
  map: mapboxgl.Map,
  routeLayerAndSourceId: string,
  vesselCoords: { lat: number; lng: number },
  airportCoords: { lat: number; lon: number },
  vesselId: number,
  airportId: string
) {
  const route: number[][] = [
    [vesselCoords.lng, vesselCoords.lat],
    [airportCoords.lon, airportCoords.lat],
  ];

  const routeGeoJSON = {
    type: 'Feature',
    properties: {
      id: `vessel-${vesselId}-airport-${airportId}`,
    },
    geometry: {
      type: 'LineString',
      coordinates: route,
    },
  };

  const lineDistance = turfLength(routeGeoJSON as GeoJSON.Feature);
  const arc = [];
  const steps = 500;
  const increment = lineDistance / steps;
  for (let i = 0; i < lineDistance; i += increment) {
    const segment = turfAlong(
      routeGeoJSON as GeoJSON.Feature<GeoJSON.LineString>,
      i
    );
    arc.push(segment.geometry.coordinates);
  }

  routeGeoJSON.geometry.coordinates = arc;
  // in cases where the route cuts the antimeridian,
  // removing the first element seems to solve the problem of the horizontal line appearing
  routeGeoJSON.geometry.coordinates.splice(0, 1);

  if (!map.getSource(routeLayerAndSourceId)) {
    map.addSource(routeLayerAndSourceId, {
      type: 'geojson',
      data: routeGeoJSON,
    } as mapboxgl.GeoJSONSourceRaw);
  } else {
    (map.getSource(routeLayerAndSourceId) as mapboxgl.GeoJSONSource).setData(
      routeGeoJSON as GeoJSON.Feature<GeoJSON.Geometry>
    );
  }

  if (!map.getLayer(routeLayerAndSourceId)) {
    map.addLayer({
      id: routeLayerAndSourceId,
      type: 'line',
      source: routeLayerAndSourceId,
      paint: {
        'line-width': 2,
        'line-color': '#007cbf',
      },
    });
  }
}

//*********************************************************************** Handlers and Listeners */

function vesselFromMouseEvent(map: mapboxgl.Map, e: MapMouseEvent) {
  const features = map.queryRenderedFeatures(e.point);
  const target = e.lngLat;
  let current: {
    feature: mapboxgl.MapboxGeoJSONFeature;
    distance: number;
  } | null = null;
  for (const v of features) {
    if (v.source !== 'vessels') {
      continue;
    }

    const coords: number[] = (v.geometry as any).coordinates;
    const point = { lng: coords[0], lat: coords[1] };
    const distance = haversine(point, target);
    if (current === null) {
      current = {
        feature: v,
        distance: distance,
      };
    } else {
      if (distance < current.distance) {
        current = {
          feature: v,
          distance: distance,
        };
      }
    }
  }

  return current;
}

function initVesselHoverEvent(
  map: mapboxgl.Map,
  setToolTipText: ToolTipSetter,
  setRoute: (v: number) => void,
  eraseCurrentRoute: (vesselId?: number | undefined) => void
) {
  let currentHoveringVesselId: number | null = null;

  const onMouseEnter = async (vesselId: number) => {
    if (!selectActive) {
      map.setFeatureState({ source: 'vessels', id: vesselId }, { hover: true });

      // Change the cursor style to pointer on hover
      map.getCanvas().style.cursor = 'pointer';

      // Set tooltip for onhover vessels
      setToolTipText('VESSEL_MARKER', 'hover');

      eraseRouteDebounce?.cancel();
      setRoute(vesselId);
    }
  };

  const onMouseLeave = (eraseRoute?: boolean) => {
    let prevHoveringVesselId = currentHoveringVesselId;
    if (currentHoveringVesselId !== null) {
      map.setFeatureState(
        { source: 'vessels', id: currentHoveringVesselId },
        { hover: false }
      );
      currentHoveringVesselId = null;
    }
    map.getCanvas().style.cursor = '';

    // Empty state resets tooltip to default state
    setToolTipText();

    if (prevHoveringVesselId && !currentHoveringVesselId && eraseRoute) {
      eraseRouteDebounce = debounce(
        (vesselId) => eraseCurrentRoute(vesselId),
        GenerateFlotillaMapConfig.debounceHoverEffectMs
      );
      eraseRouteDebounce(prevHoveringVesselId);
    }
  };

  map.on(
    'mousemove',
    debounce((e) => {
      const mouseOutPixels = 35;
      const topLeft = new mapboxgl.Point(
        e.point.x - mouseOutPixels,
        e.point.y - mouseOutPixels
      );
      const bottomRight = new mapboxgl.Point(
        e.point.x + mouseOutPixels,
        e.point.y + mouseOutPixels
      );
      if (map.getLayer('vessels-small')) {
        let features = map.queryRenderedFeatures([topLeft, bottomRight], {
          layers: ['vessels-small'],
        });

        if (!features.length) onMouseLeave(true);
      }
    }, GenerateFlotillaMapConfig.debounceMapMouseOutMs)
  );

  map.on('mousemove', 'vessels', (e) => {
    let vesselId = null;
    const { feature } = vesselFromMouseEvent(map, e) || {};

    if (feature) {
      vesselId =
        typeof feature.id === 'string' ? parseInt(feature.id) : feature.id;
    }

    if (vesselId === currentHoveringVesselId) {
      return;
    } else {
      try {
        if (currentHoveringVesselId !== null) onMouseLeave();
      } finally {
        currentHoveringVesselId = vesselId === undefined ? null : vesselId;
      }
      if (vesselId !== null && vesselId !== undefined) {
        if (currentHoveringVesselId !== null) onMouseLeave();
        currentHoveringVesselId = vesselId;
        onMouseEnter(vesselId);
      }
    }
  });
}

function initVesselClickEvent(
  map: mapboxgl.Map,
  toggleVesselsToSidePanel: (v: number[]) => void,
  clearSidePanelVessels: () => void
) {
  const addVessel = (e: mapboxgl.MapMouseEvent) => {
    const vessel = vesselFromMouseEvent(map, e);
    const feature = vessel?.feature;
    if (feature && feature.properties) {
      toggleVesselsToSidePanel([feature.properties.vesselId]);
    }
    if (feature && feature.geometry) {
      const coords = (feature.geometry as any).coordinates;
      map.flyTo({
        center: coords,
      });
    }
  };
  map.on('click', 'vessels', (e) => {
    if (!e.originalEvent.shiftKey) {
      clearSidePanelVessels();
      addVessel(e);
    }
  });
}

function initMultipleVesselSelect(
  map: mapboxgl.Map,
  toggleVesselsToSidePanel: (v: number[]) => void
) {
  const canvas = map.getCanvasContainer().parentElement;

  // Variable to hold starting xy coords when 'mousedown' triggers
  let start: mapboxgl.Point = new mapboxgl.Point(0, 0);

  // Variable to hold current xy coords when 'mousemove' or 'mouseup' triggers
  let current;

  // Variable for the draw box element
  let box: HTMLDivElement | undefined;

  // Setting `true` dispatches event before other functions call `mousedown`
  // Necessary for disabling default map dragging behavior
  if (canvas) canvas.addEventListener('mousedown', mouseDown, true);

  // Returns the xy coords of the mouse position
  function mousePos(canvas: HTMLElement, e: MouseEvent) {
    const rect = canvas.getBoundingClientRect();
    return new mapboxgl.Point(
      e.clientX - rect.left - canvas.clientLeft,
      e.clientY - rect.top - canvas.clientTop
    );
  }

  function mouseDown(e: MouseEvent) {
    // Early return if shift key is not also pressed
    // TODO: implement this for tablet, press and drag?
    if (!(e.shiftKey && e.button === 0)) return;

    selectActive = true;

    // Disable default drag zooming when the shift key is held down
    map.dragPan.disable();

    // Call functions for the following events
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('keydown', onKeyDown);

    // Capture starting xy coords
    if (canvas) start = mousePos(canvas, e);
  }

  function onMouseMove(e: MouseEvent) {
    if (!canvas) return;
    // Capture the ongoing xy coords
    current = mousePos(canvas, e);

    // Append the box element if it doesn't exist
    // TODO: Does `box` get overrode or anything as a part of the event loop?
    if (!box) {
      box = document.createElement('div');
      box.classList.add('boxdraw');
      canvas.appendChild(box);
    }

    const minX = Math.min(start.x, current.x),
      maxX = Math.max(start.x, current.x),
      minY = Math.min(start.y, current.y),
      maxY = Math.max(start.y, current.y);

    // Adjust width and xy positions of the box element ongoing
    const pos = `translate(${minX}px, ${minY}px)`;
    box.style.transform = pos;
    box.style.zIndex = '1000';
    box.style.width = `${maxX - minX}px`;
    box.style.height = `${maxY - minY}px`;
    box.style.border = '3px dashed #f24726';
    box.style.backgroundColor = 'rgba(255, 0, 0, 0.3)';
  }

  function onMouseUp(e: MouseEvent) {
    selectActive = false;

    // Capture xy coords
    finish(canvas ? [start, mousePos(canvas, e)] : null);
  }

  function onKeyDown(e: KeyboardEvent) {
    // If the ESC key is pressed
    if (e.keyCode === 27) finish(null);
  }

  function finish(bbox: [mapboxgl.Point, mapboxgl.Point] | null) {
    // Remove these events now that finish has been called
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('keydown', onKeyDown);
    document.removeEventListener('mouseup', onMouseUp);

    if (box) {
      if (box.parentNode) box.parentNode.removeChild(box);
      box = undefined;
    }

    // If bbox exists, use this value as argument for `queryRenderedFeatures
    if (bbox) {
      const features = map.queryRenderedFeatures(bbox, {
        layers: ['vessels'],
      });

      // Adds all selected vessels to side panel
      const vesselIds: number[] = features.map(
        (feature) => feature?.properties?.vesselId
      );
      if (!vesselIds.length) return;
      toggleVesselsToSidePanel(vesselIds);
      const vessels: Vessel[] = [];
      vesselIds.forEach(function (k) {
        const v = vesselsOnMap.get(k);
        if (v !== undefined) vessels.push(v);
      });
      zoomToVessels(map, vessels, true);
    }

    // Renable default map dragging functionality
    map.dragPan.enable();
  }
}

//*********************************************************************** Map Functions */

export default function generateFlotillaMap(args: {
  map: mapboxgl.Map;
  toggleVesselsToSidePanel: (v: number[]) => void;
  setToolTipText: ToolTipSetter;
  setMapState: (mapState: FlotillaMapState) => void;
  setRoute: (v: number) => void;
  eraseCurrentRoute: (vesselId?: number) => void;
  updateMapBounds: (bounds: LngLatBoundsLike) => void;
  clearSidePanelVessels: () => void;
}): MapObject {
  const {
    map,
    toggleVesselsToSidePanel,
    setToolTipText,
    setMapState,
    setRoute,
    eraseCurrentRoute,
    updateMapBounds,
    clearSidePanelVessels,
  } = args;

  let vessels = new Map();

  map.on('load', async () => {
    setMapState({ loaded: true });
    await loadImages(map);

    /*
     *  MOUSE HOVER EVENTS
     */
    initVesselHoverEvent(map, setToolTipText, setRoute, eraseCurrentRoute);

    /*
     *  MOUSE CLICK EVENTS
     */
    initVesselClickEvent(map, toggleVesselsToSidePanel, clearSidePanelVessels);

    /*
     *  SELECTING MULTIPLE VESSELS VIA DRAG-SELECT
     */
    initMultipleVesselSelect(map, toggleVesselsToSidePanel);

    const setVesselsLoaded = (c: any) => {
      if (
        c &&
        c.dataType === 'source' &&
        c.sourceId === 'vessels' &&
        // update map state when vessels data is available
        Boolean(c.source?.data?.features?.length)
      ) {
        map.off('sourcedata', setVesselsLoaded);
        setMapState({ vesselsLoaded: true, loaded: true });
      }
    };
    map.on('sourcedata', setVesselsLoaded);

    map.on('moveend', (ev: DragEvent) => {
      updateMapBounds(map.getBounds());
    });
  });

  let vesselLastMadeBig: number | null = null;

  return {
    map: map,
    remove: () => {
      console.log('remove');
      map.remove();
    },
    focusOnVessel: (v: Vessel) => {
      map.panTo({ lat: v.lat, lng: v.lng });
    },
    focusOnPort: (coords: { lat: number; lon: number }) => {
      map.panTo({ lat: coords.lat, lng: coords.lon });
    },
    makeVesselBig: (v: Vessel | null) => {
      if (v) {
        if (vesselLastMadeBig !== null) {
          map.setFeatureState(
            { source: 'vessels', id: vesselLastMadeBig },
            { hover: false }
          );
        }
        map.setFeatureState({ source: 'vessels', id: v.id }, { hover: true });
        vesselLastMadeBig = v.id;
      } else {
        if (vesselLastMadeBig !== null) {
          map.setFeatureState(
            { source: 'vessels', id: vesselLastMadeBig },
            { hover: false }
          );
        }
        vesselLastMadeBig = null;
      }
    },
    zoomToVessels: (v: Vessel[]) => zoomToVessels(map, v, true),
    setVesselMap: (v) => {
      // eslint-disable-next-line
      vessels = v; // TODO: Is this a closure we don't want?
    },
  };
}

export function getMapViewport() {
  let resLeft = 0;
  let resRight = document.body.clientWidth;
  let resTop = 0;
  let resBottom = document.body.clientHeight;

  const midVertical = resRight / 2;
  const midHorizontal = resBottom / 2;

  document.querySelectorAll<HTMLElement>('*').forEach(function (element) {
    const zIndex = parseInt(element.style.zIndex);
    if (zIndex < 10) return;

    const rect = element.getBoundingClientRect();
    if (rect.width < 40 || rect.height < 40) return;

    if (rect.right < midVertical && rect.right > resLeft) resLeft = rect.right;
    if (rect.left > midVertical && rect.right < resRight) resRight = rect.left;
    if (rect.top < midHorizontal && rect.top > resBottom) resBottom = rect.top;
    if (rect.bottom > midHorizontal && rect.bottom < resTop)
      resTop = rect.bottom;
  });

  return {
    left: resLeft,
    right: resRight,
    top: resTop,
    bottom: resBottom,
  };
}

function zoomToVessels(
  map: mapboxgl.Map,
  vessels: Vessel[],
  sidebarOn: boolean = false
) {
  if (!map || !vessels || vessels.length < 2) return;

  const targetBBox = computeBBox(vessels);

  if (sidebarOn) {
    const viewport = getMapViewport();
    const baseCoord = map.unproject(new mapboxgl.Point(0, 0));
    const sidebarCoord = map.unproject(
      new mapboxgl.Point(0, GenerateFlotillaMapConfig.sidebarWidth)
    );
    const endCoord = map.unproject(
      new mapboxgl.Point(viewport.bottom, viewport.right)
    );
    const totalWidth = Math.abs(endCoord.lat - baseCoord.lat);
    const sidebarWidth = Math.abs(sidebarCoord.lat - baseCoord.lat);
    const targetWidth = Math.abs(targetBBox.maxLng - targetBBox.minLng);
    const targetFrac = targetWidth / totalWidth;

    if (targetFrac < GenerateFlotillaMapConfig.zoomLimitFrac)
      map.fitBounds(
        new mapboxgl.LngLatBounds(
          new mapboxgl.LngLat(targetBBox.minLng - 1, targetBBox.minLat - 1),
          new mapboxgl.LngLat(
            targetBBox.maxLng + sidebarWidth * targetFrac,
            targetBBox.maxLat + 1
          )
        )
      );
  } else {
    map.fitBounds(
      new mapboxgl.LngLatBounds(
        new mapboxgl.LngLat(targetBBox.minLng + 1, targetBBox.minLat + 1),
        new mapboxgl.LngLat(targetBBox.maxLng + 1, targetBBox.maxLat + 1)
      )
    );
  }
}

function airportGeoJSON(
  hoverAirport: HoverAirport
): GeoJSON.FeatureCollection<GeoJSON.Point> {
  const { airport } = hoverAirport;
  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        id: airport.id,
        properties: {
          icon: 'flight-image',
          name: airport.text,
          label: airport.text,
        },
        geometry: {
          type: 'Point',
          coordinates: [airport.lon, airport.lat],
        },
      },
    ],
  };
}

export function addPopupFocusEventListener(
  popupClassName: string,
  vesselId: number | undefined
) {
  const focusClass = 'mapboxgl-popup-focus';

  const popupElements = document.getElementsByClassName(popupClassName);
  Array.from(popupElements).forEach((element: Element, i: number) => {
    const target = popupElements[i];
    target.addEventListener('mouseleave', () => {
      if (vesselId) {
        eraseRouteDebounce?.call(vesselId);
      }
    });
    target.addEventListener('mouseenter', () => {
      eraseRouteDebounce?.cancel();
      const elements = document.getElementsByClassName(focusClass);
      let i = 0;
      while (i < elements.length) {
        const current = elements[i];
        current.className = current.className.replace(focusClass, '').trim();
        i++;
      }

      target.className += ' ' + focusClass;
    });
  });
}

function waypointLayer(
  paintProps: Partial<mapboxgl.SymbolPaint>,
  layoutProps: Partial<mapboxgl.SymbolLayout>,
  id: string
): mapboxgl.AnyLayer {
  return {
    id: id,
    type: 'symbol',
    source: 'waypoints',
    paint: {
      ...paintProps,
    },
    layout: {
      'icon-image': ['get', 'icon'],
      'icon-allow-overlap': true,
      'text-allow-overlap': true,
      ...layoutProps,
    },
  };
}

function loadImages(map: mapboxgl.Map): Promise<Array<null>> {
  function imagePromise(url: string, name: string): Promise<null> {
    return new Promise(function (resolve, _reject) {
      loadImage(map, url, name, () => resolve(null));
    });
  }

  return Promise.all([
    imagePromise('departure.png', 'depart-wp-marker'),
    imagePromise('arrival.png', 'arrival-wp-marker'),
    imagePromise('linestyle.png', 'line-texture'),
    imagePromise('flight.png', 'flight-image'),
  ]);
}
