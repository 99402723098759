import {
  MouseEvent,
  forwardRef,
  useRef,
  useState,
  lazy,
  Suspense,
  Dispatch,
  SetStateAction,
  FormEvent,
} from 'react';
import styled from 'styled-components/macro';
import checkForHandover, {
  HandoverArgs,
} from 'frontend-library/dist/utils/auth';
import { getPlatformUrl } from 'frontend-library/dist/utils/platform';

// mui imports
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';

import { blue, textBlack } from 'lib/colors';
import { trackUserAction } from 'lib/amplitude';
import { handleLogin, setSessionKeys } from 'utils/handle-login';
import { Button, GreywingLogo } from 'components/shared';
import { Text } from 'components/shared/Typography';
import { BREAK_POINT_XS } from 'lib/breakpoints';

const InputAdornment = lazy(() => import('@mui/material/InputAdornment'));
const IconButton = lazy(() => import('@mui/material/IconButton'));
const Visibility = lazy(() => import('@mui/icons-material/Visibility'));
const VisibilityOff = lazy(() => import('@mui/icons-material/VisibilityOff'));

const StyledContainer = styled(Paper)`
  width: 100%;
  width: 100%;
  max-width: 350px;
  max-height: 600px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);

  p {
    margin-bottom: 1rem;
    a {
      color: ${blue};
      font-weight: 400;
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: ${BREAK_POINT_XS}) {
    max-width: unset;
    max-height: unset;
    height: 95%;
  }
`;

const SubmitButton = styled(Button)`
  margin-right: 0;
  margin-bottom: 1rem;
  height: 40px;
  font-size: 1rem;
`;

const Divider = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
  color: ${textBlack};

  &::before {
    margin-right: 1rem;
  }
  &::after {
    margin-left: 1rem;
  }
  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #1d3a5b;
  }
`;

const SocialMediaButton = styled(Button)`
  margin-right: 0;
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
`;

const StyledGWLogo = styled(GreywingLogo)`
  margin: 30px auto;
`;

const SocialMediaLogo = styled.img`
  margin-right: 0.5rem;
  width: 20px;
`;

interface IAuthFormProps {
  setIsAuthed: Dispatch<SetStateAction<boolean>>;
}

const AuthForm = forwardRef<HTMLDivElement, IAuthFormProps>(
  ({ setIsAuthed }, ref) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const inputRef = useRef<{
      email: HTMLInputElement | null;
      password: HTMLInputElement | null;
    }>({
      email: null,
      password: null,
    });

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setError('');
      const { email, password } = inputRef?.current;
      const loggedInTime = new Date().toISOString();

      if (email?.value && password?.value) {
        const response = await handleLogin({
          email: email.value,
          password: password.value,
        });

        if (!response) {
          setError('No valid response');
          return;
        }
        const { success, errorWithLogin, message, authPacket } = response;

        if (success) {
          setSessionKeys(authPacket);
          setIsAuthed(true);
          // track user login with login time
          trackUserAction('login:v2-flotilla', {
            email: email.value,
            time: loggedInTime,
          });
        } else {
          if (errorWithLogin) {
            const handoverArgs: HandoverArgs = {
              skipInitAmplitude: process.env.REACT_APP_LOCAL_DEV === 'true',
              skipInitSentry: process.env.REACT_APP_LOCAL_DEV === 'true',
              skipInitIntercom: process.env.REACT_APP_LOCAL_DEV === 'true',
            };

            checkForHandover(handoverArgs);
          } else {
            setError(message || 'No valid response');
          }
        }
      }
    };

    return (
      <StyledContainer ref={ref} tabIndex={-1}>
        <StyledForm onSubmit={handleSubmit}>
          <StyledGWLogo />
          {error && (
            <Text variant="error" align="center">
              {error}
            </Text>
          )}
          <TextField
            required
            sx={{ marginBottom: 2 }}
            inputRef={(el) => (inputRef.current['email'] = el)}
            id="email"
            name="email"
            label="Email address"
            size="small"
          />

          <FormControl variant="outlined" size="small" sx={{ marginBottom: 2 }}>
            <InputLabel htmlFor="passwoord">Password</InputLabel>
            <OutlinedInput
              required
              type={showPassword ? 'text' : 'password'}
              inputRef={(el) => (inputRef.current['password'] = el)}
              id="password"
              name="email"
              label="Password"
              size="small"
              endAdornment={
                <Suspense fallback={<></>}>
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                </Suspense>
              }
            />
          </FormControl>

          <SubmitButton variant="primary" onClick={handleSubmit} type="submit">
            Sign In
          </SubmitButton>

          <Text variant="muted" align="center">
            Don't have an account yet?{' '}
            <a href={`${getPlatformUrl()}/sign-up`}>Sign Up</a>.
          </Text>

          <Divider>Or sign in with</Divider>

          <SocialMediaButton variant="secondary" onClick={() => {}}>
            <SocialMediaLogo src="google.png" alt="" /> Google
          </SocialMediaButton>

          <SocialMediaButton variant="secondary" onClick={() => {}}>
            <SocialMediaLogo src="linkedin.png" alt="" /> LinkedIn
          </SocialMediaButton>

          <SocialMediaButton variant="secondary" onClick={() => {}}>
            <SocialMediaLogo src="facebook.png" alt="" /> Facebook
          </SocialMediaButton>
        </StyledForm>
      </StyledContainer>
    );
  }
);

export default AuthForm;
