import * as Sentry from '@sentry/react';
import { getAPIUrl } from 'frontend-library/dist/utils/platform';
import {
  AMPLITUDE_TOKEN,
  INTERCOM_EMAIL_TOKEN,
  INTERCOM_HASH_TOKEN,
  SENTRY_DSN_TOKEN,
  SENTRY_TAG_TOKEN,
  SESSION_TOKEN,
  SESSION_TOKEN_EXPIRY,
  USERID_TOKEN,
} from 'frontend-library/dist/config/tokens';

export const handleLogin = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  try {
    const response = await fetch(`${getAPIUrl()}/api/v2/users/sign-in`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({ email, password }),
    });

    return response.json();
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const setSessionKeys = ({
  analytics,
  api,
  dsn,
  expires,
  icEmail,
  icHash,
  tag,
  token,
  userId,
}: {
  [key: string]: string;
}) => {
  if (api) {
    localStorage.setItem('API_URL', api);
  }

  if (token) {
    localStorage.setItem(SESSION_TOKEN, token);
  }

  if (expires) {
    localStorage.setItem(SESSION_TOKEN_EXPIRY, expires);
  }

  if (analytics) {
    localStorage.setItem(AMPLITUDE_TOKEN, analytics);
  }

  if (userId) {
    localStorage.setItem(USERID_TOKEN, userId);
  }

  if (tag) {
    localStorage.setItem(SENTRY_TAG_TOKEN, tag);
  }

  if (dsn) {
    localStorage.setItem(SENTRY_DSN_TOKEN, dsn);
  }

  if (icEmail) {
    localStorage.setItem(INTERCOM_EMAIL_TOKEN, icEmail);
  }

  if (icHash) {
    localStorage.setItem(INTERCOM_HASH_TOKEN, icHash);
  }
};
