import { useContext, useMemo, useEffect } from 'react';
import styled from 'styled-components/macro';

// Components
import CardContainer from './CardContainer';
import LabelTitle from './common/LabelTitle';

// Context
import { StoreContext } from 'store/Store';
import { SidePanelFieldContext } from 'contexts/sidepanel-context';

// Types and Utilities
import { AISEngineVessel, VesselFieldId } from 'utils/types';
import {
  getSidePanelDisplayableFields,
  vesselFields,
  stringifyFieldValue,
  getFieldHighlight,
} from 'utils/vesselFields';

const AdditionalInfoWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0;
  row-gap: 1rem;
  li {
    margin: 0 !important;
    flex: 1 1 50%;
    list-style-type: none;
  }
`;

const FieldInfo = styled.li`
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: space-between;
`;

const FIELDS_IN_COURSE_HEADER: Array<VesselFieldId> = [
  'imo',
  'secondsToNextPort',
  'speed',
];

const VesselInfo = ({ vessel }: { vessel: AISEngineVessel }) => {
  const { flotillaConfig, flotilla } = useContext(StoreContext);
  const { AISVesselsFull } = flotilla;
  const { activeRefs, highlightedField } = useContext(SidePanelFieldContext);
  const { colorHighlightField } = flotillaConfig;

  const highlightStyleColor = useMemo(() => {
    const vesselHighlights = getFieldHighlight(
      colorHighlightField as string,
      Array.from(AISVesselsFull.values())
    );
    return vesselHighlights?.getHighlightColor(vessel) || '';
  }, [AISVesselsFull, vessel, colorHighlightField]);

  const highlightedFieldData = useMemo(() => {
    return vesselFields[colorHighlightField as VesselFieldId];
  }, [colorHighlightField]);

  useEffect(() => {
    if (
      highlightedField &&
      highlightedField in activeRefs.current &&
      activeRefs.current[highlightedField]
    ) {
      activeRefs.current[highlightedField].scrollIntoView(true, {
        behavior: 'smooth',
      });
    }
  }, [highlightedField, activeRefs]);

  const displayFields = useMemo(() => {
    return getSidePanelDisplayableFields()
      .reduce((acc: Array<any>, fieldId) => {
        if (
          !(
            flotillaConfig.sidePanelFields.includes(fieldId) &&
            FIELDS_IN_COURSE_HEADER.includes(fieldId)
          )
        ) {
          return acc;
        }
        if (
          fieldId in vesselFields &&
          !vesselFields[fieldId].emptyValues.includes(vessel[fieldId])
        ) {
          if (fieldId === 'secondsToNextPort') {
            // Manually force it to go to the far bottom
            acc.push({
              fieldId,
              data: { ...vesselFields[fieldId], displayPriority: 999 },
            });
          } else {
            acc.push({ fieldId, data: vesselFields[fieldId] });
          }
        }
        return acc;
      }, [])
      .sort((a, b) => a.data.displayPriority - b.data.displayPriority);
  }, [flotillaConfig.sidePanelFields, vessel]);

  if (!displayFields.length) return null;
  return (
    <CardContainer>
      <AdditionalInfoWrapper>
        {displayFields.map(({ fieldId, data }) => (
          <FieldInfo
            key={fieldId}
            ref={(element) => ((activeRefs.current as any)[fieldId] = element)}
          >
            <LabelTitle>{data.longDesc}</LabelTitle>
            <p style={{ margin: 0 }}>
              {stringifyFieldValue(vessel[fieldId], data)}
            </p>
          </FieldInfo>
        ))}
        {highlightedFieldData && (
          <FieldInfo>
            <LabelTitle>{highlightedFieldData.longDesc}</LabelTitle>
            <p style={{ margin: 0, color: highlightStyleColor }}>
              {stringifyFieldValue(
                vessel[colorHighlightField as VesselFieldId],
                highlightedFieldData
              )}
            </p>
          </FieldInfo>
        )}
      </AdditionalInfoWrapper>
    </CardContainer>
  );
};

export default VesselInfo;
