// New
import React, { useContext, useEffect, useState, Suspense, lazy } from 'react';
import { Modal } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Context Imports
import { StoreContext } from './store/Store';

import AuthForm from './components/Auth/AuthForm';

import { verifyAuth } from 'frontend-library/dist/utils/auth';
import { initAmplitude } from 'frontend-library/dist/utils/amplitude';

import { FlotillaConfigState, FLOTILLA_CONFIG_STATE_KEY } from './utils/types';
import { getUserInfo } from './api/flotilla';

import authFormStyles from './styles/components/Auth/AuthForm.module.scss';

import { setSessionKeys } from './utils/handle-login';
import {
  AMPLITUDE_TOKEN,
  HANDOVER_TOKEN,
  INTERCOM_EMAIL_TOKEN,
  INTERCOM_HASH_TOKEN,
  SENTRY_DSN_TOKEN,
  SENTRY_TAG_TOKEN,
  USERID_TOKEN,
} from 'frontend-library/dist/config/tokens';

import ErrorBoundary from './components/ErrorBoundary';
import { clearMap } from 'map/map';

const FlotillaPage = lazy(() => import('./pages/FlotillaPage'));

const App: React.FC<{}> = () => {
  const isHandOver = new URLSearchParams(window.location.hash).get('api');
  const { flotilla, flotillaConfig, flotillaConfigDispatch } =
    useContext(StoreContext);
  const [isAuthed, setIsAuthed] = useState<boolean>(!isHandOver);

  useEffect(() => {
    if (flotilla === null) return;
    getUserInfo()
      .then((res) => {
        if (res.success && res.userConfig) {
          flotillaConfigDispatch.setUserInfo(res.userConfig);
          return res.userConfig;
        }
      })
      .catch((err) => {
        console.error('error getting user info', err);
      });
  }, [isAuthed]); // eslint-disable-line

  useEffect(() => {
    (async () => {
      console.log('Checking for handover...');

      // this if-block can be removed once we decommission V1 and don't have to support handover
      if (window.location.hash && window.location.hash.length) {
        const tokens: { [key: string]: string } = {};
        window.location.hash
          .substring(1)
          .split('&')
          .map((token) => token.split('='))
          .forEach((token) => {
            tokens[token[0]] = token[1];
          });

        const handoverToken = tokens[HANDOVER_TOKEN];

        if (handoverToken) {
          const defaultExpiryDate = new Date();
          // Default expiry set to seven days, this shouldn't happen.
          defaultExpiryDate.setTime(
            defaultExpiryDate.getTime() + 7 * 24 * 60 * 60 * 1000
          );
          const expiresToken = tokens['expires'];
          const expires =
            (expiresToken && decodeURIComponent(expiresToken)) ||
            defaultExpiryDate.toUTCString();

          setSessionKeys({
            analytics: tokens[AMPLITUDE_TOKEN],
            api: tokens['api'],
            dsn: tokens[SENTRY_DSN_TOKEN],
            expires,
            icEmail: tokens[INTERCOM_EMAIL_TOKEN],
            icHash: tokens[INTERCOM_HASH_TOKEN],
            tag: tokens[SENTRY_TAG_TOKEN],
            token: handoverToken,
            userId: tokens[USERID_TOKEN],
          });
        }
      }

      const checkAuthed = async () => {
        try {
          const isAuthCheck = await verifyAuth();
          console.log('auth check', isAuthCheck);

          setIsAuthed(isAuthCheck);

          const skipInitAmplitude = process.env.REACT_APP_LOCAL_DEV === 'true';
          if (!skipInitAmplitude) {
            initAmplitude();
          }

          return isAuthCheck;
        } catch (err) {
          console.log('err', err);
          return false;
        }
      };

      checkAuthed();
    })();
  }, []); // eslint-disable-line

  useEffect(() => {
    storeConfig(flotillaConfig);
  }, [flotillaConfig]);

  function onRemoveFilter(index: number) {
    flotillaConfigDispatch.removeSearchFilter(index);
  }

  function clearFilters() {
    flotillaConfigDispatch.clearSearchFilters();
  }

  const clearState = () => {
    clearMap();
    flotilla.resetFlotillaState();
  };

  // useEffect(() => {
  //   if (isAuthed) {
  //     console.log('Building index...');
  //     const filteredVessels = applyFilters(
  //       flotilla.vesselsFull,
  //       flotillaConfig.searchFilters
  //     );
  //     buildVesselsIndex(filteredVessels, flotilla.setSearchReady);
  //     flotilla.populateFilteredVessels(filteredVessels);
  //   }
  // }, [flotilla.vesselsFullUpdatedAt, flotillaConfig.searchFilters]); // eslint-disable-line

  // This bit of code provides the method to handle 100vh issues on mobile
  useEffect(() => {
    function setVh() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    window.addEventListener('resize', setVh);
    // Run on first load
    setVh();
    return () => {
      window.removeEventListener('resize', setVh);
    };
  }, []);

  return (
    <ErrorBoundary>
      <Modal
        disableEscapeKeyDown
        open={!isAuthed}
        className={authFormStyles.modalWrapper}
      >
        <AuthForm setIsAuthed={setIsAuthed} />
      </Modal>
      {isAuthed ? (
        <Suspense fallback={<></>}>
          <FlotillaPage
            onRemoveFilter={onRemoveFilter}
            clearFilters={clearFilters}
            setIsAuthed={setIsAuthed}
            onLogout={clearState}
          />
        </Suspense>
      ) : (
        <div className={authFormStyles.mapPlaceholder}></div>
      )}
      <ToastContainer />
    </ErrorBoundary>
  );
};

function storeConfig(flotillaConfig: FlotillaConfigState) {
  window.localStorage.setItem(
    FLOTILLA_CONFIG_STATE_KEY,
    JSON.stringify(flotillaConfig)
  );
}

// (FlotillaPage as any).whyDidYouRender = true;

export default App;
