import { useReducer as standardUseReducer } from 'react';
import { useReducer as inspectableUseReducer } from 'reinspect';

import {
  SidePanelAction,
  SidePanelDispatches,
  SidePanelState,
  HoverAirport,
} from '../../utils/sidepanel-types';

import { sendEvent } from '../utils';

const initialState: SidePanelState = {
  airportOnHover: null,
};

export const createSidePanelDispatches: (
  s: (a: SidePanelAction) => void
) => SidePanelDispatches = (dispatch) => ({
  setOrUnsetAirportOnHover: (airport: HoverAirport | null) => {
    dispatch({
      type: 'SET_OR_UNSET_AIRPORT_HOVER',
      payload: airport,
    });
  },
});

const reducer: (
  a: SidePanelState | undefined,
  b: SidePanelAction
) => SidePanelState = (state = initialState, action: SidePanelAction) => {
  sendEvent(action);

  switch (action.type) {
    case 'SET_OR_UNSET_AIRPORT_HOVER':
      return {
        ...state,
        airportOnHover: action.payload,
      };

    default:
      return state;
  }
};

export const useSidePanelReducer = () => {
  if (process.env.REACT_APP_ENABLE_REDUX_INSPECT === 'yes') {
    return inspectableUseReducer(
      reducer,
      initialState,
      (identity) => identity,
      'sidepanel-store'
    );
  } else {
    return standardUseReducer(reducer, initialState);
  }
};

if (process.env.REACT_APP_ENABLE_REDUX_INSPECT === 'yes')
  console.log('Inspect mode: Sidepanel reducer is inspectable...');
