import { LngLatBounds, LngLatBoundsLike } from 'mapbox-gl';
import { useReducer as standardUseReducer } from 'react';
import { useReducer as inspectableUseReducer } from 'reinspect';

import { SIDE_PANEL_DEFAULT_EXCLUSION } from 'lib/constants';
import {
  FlotillaConfigAction,
  FlotillaConfigState,
  FlotillaConfigDispatches,
  SearchFilter,
  FLOTILLA_CONFIG_STATE_KEY,
  UserInfo,
} from 'utils/types';
import { UserNotificationEvent } from 'utils/types/notification-event-types';
import {
  getHighlightableFields,
  getLabellableFields,
  getPopuppableFields,
  getSidePanelDisplayableFields,
} from 'utils/vesselFields';

import { sendEvent } from '../utils';
import { compoundSidePanelFields } from 'components/SidePanel/CompoundSidePanelFields';

export const createFlotillaConfigDispatches: (
  f: (a: FlotillaConfigAction) => void
) => FlotillaConfigDispatches = (flotillaConfigDispatch) => ({
  loadConfig: (config: FlotillaConfigState): void => {
    flotillaConfigDispatch({
      type: 'LOAD_CONFIG',
      payload: config,
    });
  },

  updateColorHighlightField: (highlightField: string | null): void => {
    flotillaConfigDispatch({
      type: 'UPDATE_COLOR_HIGHLIGHT_FIELD',
      payload: highlightField,
    });
  },

  updatePopUpFields: (popUpFields: string[]): void => {
    flotillaConfigDispatch({
      type: 'UPDATE_POPUP_FIELDS',
      payload: popUpFields,
    });
  },

  setUserInfo: (userInfo: UserInfo): void => {
    try {
      if ((window as any).hj) {
        (window as any).hj('identify', userInfo.id, {
          email: userInfo.email,
          company: userInfo.company_id,
        });
      }
    } catch (err) {
      console.error('Could not set user info to hj.');
    }

    flotillaConfigDispatch({
      type: 'SET_USER_INFO',
      payload: userInfo,
    });
  },

  setUserNotificationEvents: (events: UserNotificationEvent[]): void => {
    flotillaConfigDispatch({
      type: 'SET_USER_NOTIFICATION_EVENTS',
      payload: events,
    });
  },

  updateSidePanelFields: (sidePanelFields: string[]): void => {
    flotillaConfigDispatch({
      type: 'UPDATE_SIDEPANEL_FIELDS',
      payload: sidePanelFields,
    });
  },

  updateLabelField: (labelField: string): void => {
    flotillaConfigDispatch({
      type: 'UPDATE_LABEL_FIELD',
      payload: labelField,
    });
  },

  updateMapBounds: (bounds: LngLatBoundsLike): void => {
    flotillaConfigDispatch({
      type: 'UPDATE_MAP_BOUNDS',
      payload: bounds,
    });
  },

  addSearchFilter: function (filter: SearchFilter) {
    flotillaConfigDispatch({
      type: 'ADD_SEARCH_FILTER',
      payload: filter,
    });
  },

  removeSearchFilter: function (index: number) {
    flotillaConfigDispatch({
      type: 'REMOVE_SEARCH_FILTER',
      payload: index,
    });
  },

  clearSearchFilters: function () {
    flotillaConfigDispatch({
      type: 'CLEAR_SEARCH_FILTERS',
    });
  },
});

const initialFlotillaConfigState: FlotillaConfigState = loadInitialConfig();

const defaultBounds = new LngLatBounds([
  [188, 81],
  [-150, -71],
]);

function loadInitialConfig(): FlotillaConfigState {
  const defaultBounds = new LngLatBounds([
    [188, 81],
    [-150, -71],
  ]);

  const defaultConfig = {
    colorHighlightField: 'type',
    popupFields: [
      'imo',
      'secondsToNextPort',
      'speed',
      'nextPortName',
      'arrival',
    ],
    sidePanelFields: getSidePanelDisplayableFields().filter(
      (fieldId) => !SIDE_PANEL_DEFAULT_EXCLUSION.includes(fieldId)
    ),
    vesselLabelField: 'label_name',
    searchFilters: [],
    map: {
      bounds: defaultBounds,
    },
    userInfo: undefined,
    userNotificationEvents: [],
  };

  const stored = getStoredConfig();
  return stored ? { ...defaultConfig, ...stored } : defaultConfig;
}

function getStoredConfig(): FlotillaConfigState | null {
  let flotillaConfigState: FlotillaConfigState | null = null;
  try {
    const tmp = window.localStorage.getItem(FLOTILLA_CONFIG_STATE_KEY);
    if (tmp) {
      flotillaConfigState = JSON.parse(tmp);
      if (flotillaConfigState) {
        // There's an issue deserializing the bounds loaded from localstorage, hence we need parseBounds().
        flotillaConfigState.map = {
          bounds: parseBounds(flotillaConfigState?.map?.bounds),
        };
        const sidePanelFields = getSidePanelDisplayableFields();
        const { sidePanelFields: existingFields } = flotillaConfigState;
        const updated: string[] = sidePanelFields.reduce(
          (acc: string[], fieldId) => {
            // always include persistent fields
            if (
              fieldId in compoundSidePanelFields &&
              compoundSidePanelFields[fieldId].persistent
            ) {
              acc.push(fieldId);
            }
            // check the existing fields if it is still part of the MASTER fields
            if (existingFields.includes(fieldId)) {
              acc.push(fieldId);
            }
            return acc;
          },
          []
        );
        flotillaConfigState.sidePanelFields = updated;

        // Update store
        localStorage.setItem(
          'flotillaConfig',
          JSON.stringify(flotillaConfigState)
        );
      }
    }
  } catch (err) {
    console.log(
      'error parsing flotilla config state from local storage. erasing it...',
      err
    );
    window.localStorage.removeItem(FLOTILLA_CONFIG_STATE_KEY);
  }

  return flotillaConfigState;
}

function parseBounds(
  bounds: LngLatBoundsLike | null | undefined
): LngLatBounds {
  if (!bounds) {
    return defaultBounds;
  }

  try {
    const untypeBounds = bounds as any;
    if (
      typeof untypeBounds._sw?.lng === 'number' &&
      typeof untypeBounds._sw?.lat === 'number' &&
      typeof untypeBounds._ne?.lng === 'number' &&
      typeof untypeBounds._ne?.lat === 'number'
    ) {
      return new LngLatBounds([
        [untypeBounds._sw.lng, untypeBounds._sw.lat],
        [untypeBounds._ne.lng, untypeBounds._ne.lat],
      ]);
    }
  } catch (err) {
    console.log(`error parsing bounds ${bounds}:`, err);
  }

  return defaultBounds;
}

const reducer: (
  a: FlotillaConfigState | undefined,
  b: FlotillaConfigAction
) => FlotillaConfigState = (
  state = initialFlotillaConfigState,
  action: FlotillaConfigAction
) => {
  const highlightableFields = getHighlightableFields();
  const labellableFields = getLabellableFields();
  const popuppableFields = getPopuppableFields();
  const sidePanelDisplayableFields = getSidePanelDisplayableFields();

  sendEvent(action);

  switch (action.type) {
    case 'LOAD_CONFIG':
      return {
        ...action.payload,
      };
    case 'SET_USER_INFO':
      return {
        ...state,
        userInfo: action.payload,
      };
    case 'SET_USER_NOTIFICATION_EVENTS':
      return {
        ...state,
        userNotificationEvents: Array.from(action.payload),
      };
    case 'UPDATE_COLOR_HIGHLIGHT_FIELD':
      if (action.payload && highlightableFields.indexOf(action.payload) !== -1)
        return {
          ...state,
          colorHighlightField: action.payload,
        };
      return {
        ...state,
        colorHighlightField: null,
      };
    case 'UPDATE_LABEL_FIELD':
      if (labellableFields.indexOf(action.payload) !== -1)
        return {
          ...state,
          vesselLabelField: action.payload,
        };
      return state;
    case 'UPDATE_POPUP_FIELDS':
      return {
        ...state,
        popupFields: action.payload.filter((fieldId) =>
          popuppableFields.includes(fieldId)
        ),
      };
    case 'UPDATE_SIDEPANEL_FIELDS':
      return {
        ...state,
        sidePanelFields: action.payload.filter((fieldId) =>
          sidePanelDisplayableFields.includes(fieldId)
        ),
      };
    case 'UPDATE_MAP_BOUNDS':
      return {
        ...state,
        map: {
          ...state.map,
          bounds: action.payload,
        },
      };

    case 'ADD_SEARCH_FILTER':
      const filters = Array.from(state.searchFilters);
      const { payload } = action;
      const hasETAFilterBefore =
        payload.field === 'eta-end' &&
        filters.some(({ field }) => field === 'eta-end');

      let newFilters = [...filters, payload];
      if (hasETAFilterBefore) {
        newFilters = [
          ...filters.filter(({ field }) => field !== 'eta-end'),
          payload,
        ];
      }
      const hasETAFilterAfter =
        payload.field === 'eta-start' &&
        filters.some(({ field }) => field === 'eta-start');

      if (hasETAFilterAfter) {
        newFilters = [
          ...filters.filter(({ field }) => field !== 'eta-start'),
          payload,
        ];
      }

      return {
        ...state,
        searchFilters: newFilters,
      };

    case 'REMOVE_SEARCH_FILTER':
      const removedFilters = Array.from(state.searchFilters);
      removedFilters.splice(action.payload, 1);
      return {
        ...state,
        searchFilters: removedFilters,
      };

    case 'CLEAR_SEARCH_FILTERS':
      return {
        ...state,
        searchFilters: [],
      };
  }
};

export const useFlotillaConfigReducer = () => {
  if (process.env.REACT_APP_ENABLE_REDUX_INSPECT === 'yes') {
    return inspectableUseReducer(
      reducer,
      initialFlotillaConfigState,
      (identity) => identity,
      'config-store'
    );
  } else {
    return standardUseReducer(reducer, initialFlotillaConfigState);
  }
};

if (process.env.REACT_APP_ENABLE_REDUX_INSPECT === 'yes')
  console.log('Inspect mode: Config reducer is inspectable...');
