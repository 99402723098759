import styled from 'styled-components/macro';

// Styles
import { textGray } from 'lib/colors';

const LabelTitle = styled.span`
  display: inline-block;
  color: ${textGray};
  font-size: 0.7rem;
  text-transform: uppercase;
  line-height: 1;
`;

export default LabelTitle;
