import { CircularProgress } from '@mui/material';
import { gray } from 'lib/colors';
import { memo } from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div<{ $width?: number }>`
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: ${({ $width }) => ($width ? `${$width}px` : '100%')};
  padding: 15px;
  height: fit-content;
  background: #fff;
`;

type Props = {
  width?: number;
  children: React.ReactNode;
  isLoading?: boolean;
  style?: React.CSSProperties;
  onClick?: () => void;
};

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${gray};
`;

function Card({ width, children, isLoading, ...rest }: Props) {
  return (
    <Wrapper $width={width} {...rest}>
      {isLoading && (
        <LoadingWrapper>
          <CircularProgress size={12} />
        </LoadingWrapper>
      )}
      {children}
    </Wrapper>
  );
}

export default memo(Card);
