import { createContext, useContext, useMemo } from 'react';

import {
  createFlotillaDispatches,
  useFlotillaReducer,
} from './flotilla/flotilla-store';
import {
  createFlotillaConfigDispatches,
  useFlotillaConfigReducer,
} from './config/config-store';
import {
  createSidePanelDispatches,
  useSidePanelReducer,
} from './sidepanel/sidepanel-store';

import {
  FlotillaState,
  FlotillaDispatches,
  FlotillaConfigState,
  FlotillaConfigDispatches,
} from '../utils/types';

import { SidePanelState, SidePanelDispatches } from '../utils/sidepanel-types';

export interface IStoreContext {
  flotilla: FlotillaState & FlotillaDispatches;
  // Separating FlotillaConfigState and FlotillaConfigDispatches because we want to save FlotillaConfigState to localStorage whenever it changes.
  // This means subscribing changes on the whole instance. If FlotillaConfigDispatches and FlotillaConfigState are combined, FlotillaConfigDispatches'
  // methods will trigger event hooks on every little user movement even when they don't modify FlotillaConfigState.
  flotillaConfig: FlotillaConfigState;
  flotillaConfigDispatch: FlotillaConfigDispatches;
  sidePanel: SidePanelState & SidePanelDispatches;
}

// Default values and react man - this seems to be one of the recommended ways of doing it, since
// accessing values outside context will cause issues anyway
// https://stackoverflow.com/questions/61333188/react-typescript-avoid-context-default-value
export const StoreContext = createContext<IStoreContext>({} as IStoreContext);
StoreContext.displayName = 'FlotillaStoreAndConfig';

interface StoreProviderParams {
  children: any;
}

export default function StoreProvider({ children }: StoreProviderParams) {
  const [flotillaState, flotillaDispatch] = useFlotillaReducer();
  const flotillaDispatches = useMemo(
    () => createFlotillaDispatches(flotillaDispatch),
    [flotillaDispatch]
  );

  const flotillaStore = {
    ...flotillaState,
    ...flotillaDispatches,
  };

  const [flotillaConfigState, flotillaConfigDispatch] =
    useFlotillaConfigReducer();
  const flotillaConfigDispatches = useMemo(
    () => createFlotillaConfigDispatches(flotillaConfigDispatch),
    [flotillaConfigDispatch]
  );

  const [sidePanelState, sidePanelDispatch] = useSidePanelReducer();
  const sidePanelDispatches = useMemo(
    () => createSidePanelDispatches(sidePanelDispatch),
    [sidePanelDispatch]
  );

  const sidePanelStore = {
    ...sidePanelState,
    ...sidePanelDispatches,
  };

  return (
    <StoreContext.Provider
      value={{
        flotilla: flotillaStore,
        flotillaConfig: flotillaConfigState,
        flotillaConfigDispatch: flotillaConfigDispatches,
        sidePanel: sidePanelStore,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
}

export const useStoreContext = () => useContext(StoreContext);
