import MUITooltip, {
  TooltipProps,
  tooltipClasses,
} from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import { blue } from 'lib/colors';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MUITooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: `${blue}`,
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: '0.8rem',
    fontFamily: 'HK Grotesk, Roboto',
    fontWeight: 'normal',
    maxWidth: '500px',
  },
}));

type Props = {
  content: string;
  children: React.ReactElement<any, any>; // based on MUI type
};

function Tooltip({ content, children }: Props) {
  return (
    <StyledTooltip title={content} placement="top" arrow>
      {children}
    </StyledTooltip>
  );
}

export default Tooltip;
