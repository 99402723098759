// General
export const blue = '#385dea';
export const routeBlue = '#869bf5';
export const lightBlue = '#bbc7f7';
export const borderGray = '#e0e0e0';
export const gray = '#f5f5f5';
export const lightGray = '#fafafa';
export const black = '#000';
export const white = '#fff';
export const fadedGreen = '#4ECDC4';
export const lightFadedGreen = 'rgba(78,205,196,0.18)';
export const green = '#50b72e';
export const purple = '#534AB5';
export const red = '#FF6B6B';
export const yellowOrange = '#F29F39';
export const bluePurple = '#DCE4F3';
export const darkBlue = '#233a88';
export const blueGray = '#5972A3';
export const hoverGray = '#E7E7E9';
// export const red = red;

export const lightPurple = 'rgb(242, 241, 249)'; // .08 * purple
export const midPurple = 'rgb(227, 226, 243)'; // .16 * purple
export const midBlue = 'rgb(229, 243, 255)'; // .16 * blue

// Typography
export const textBlack = '#2a2a2c';
export const iconGray = '#bdbdbd';
export const textGray = '#9e9e9e';

// Button Colors
export const inactivePrimary = '#889ef3';
export const inactiveSecondary = '#bdbdbd';

export const inactiveTextWhite = 'rgba(255, 255, 255, 0.4)';
export const inactiveTextSecondary = textGray;
